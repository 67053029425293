/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiHealth, EuiLink, EuiPanel, EuiSpacer, EuiTableRowCell, EuiText } from '@elastic/eui'

import type { CuiTableColumn } from '@modules/cui/Table'
import { CuiTable } from '@modules/cui/Table'
import { CuiHelpTipIcon } from '@modules/cui/HelpTipIcon'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { ProjectInstance } from '@modules/billing-api/customTypes'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import EmptyDataPrompt from '@/components/User/BillingUsage/BillingUsageOverviewV2/EmptyDataPrompt'
import DocLink from '@/components/DocLink'

import AggregationTableRowV2 from '../components/AggregationTableRowV2'
import { ColorKeyForOtherProjects, VisibleProjects } from '../constants'
import useFilters from '../components/Filters/useFilters'
import { billingResponsivePanelCss } from '../styles'

import { getRowColor, getTotalProjectCollapsedCosts, getTotalProjectCosts } from './utils'
import { IconType } from './types'
import DetailsPanel from './DetailsPanel'

import type { ProjectSolution, TotalProjectCollapsedCosts } from './types'
import type { FunctionComponent } from 'react'

interface Props {
  instances: ProjectInstance[]
  aggregated: boolean
  isLoading: boolean
  onProjectSelected: (projectId: string, projectName?: string) => void
  organizationId: string
}

const ProjectsList: FunctionComponent<Props> = ({
  instances,
  aggregated,
  isLoading,
  onProjectSelected,
  organizationId,
}) => {
  const { filters } = useFilters()
  const items = instances.sort((a, b) => b.total_ecu - a.total_ecu)
  const totalProjectCosts = getTotalProjectCosts(items)
  const visibleItems = items.slice(0, VisibleProjects)
  const collapsedItems = items.slice(VisibleProjects)
  const collapsedTotals = getTotalProjectCollapsedCosts(collapsedItems)
  const hasAnyProjects = Boolean(visibleItems.length)

  const getSolutionName = (solution: ProjectSolution) =>
    ({
      elasticsearch: (
        <FormattedMessage
          id='billing-usage-project-list.search-type'
          defaultMessage='Elasticsearch'
        />
      ),
      observability: (
        <FormattedMessage
          id='billing-usage-project-list.observability-type'
          defaultMessage='Observability'
        />
      ),
      security: (
        <FormattedMessage id='billing-usage-project-list.security-type' defaultMessage='Security' />
      ),
    }[solution])

  const renderGroupedSolutions = (solutions: ProjectSolution[]) =>
    solutions.map((solution, i) => [i > 0 && ', ', getSolutionName(solution)])

  const columns: Array<CuiTableColumn<ProjectInstance>> = [
    {
      label: <FormattedMessage id='billing-usage-project-list.name-label' defaultMessage='Name' />,
      render: ({ name, id }) => (
        <EuiHealth
          color={aggregated ? colorForInstances.get(ColorKeyForOtherProjects) : getRowColor(id)}
        >
          <EuiLink disabled={!id} onClick={() => onProjectSelected(id, name)}>
            <span data-test-id='name'>{name || id}</span>
          </EuiLink>

          {name && (
            <Fragment>
              <EuiSpacer size='xs' />
              <EuiText color='subdued' size='xs'>
                {id}
              </EuiText>
            </Fragment>
          )}
        </EuiHealth>
      ),
      width: '70%',
      footer: {
        render: () => (
          <Fragment>
            <FormattedMessage id='billing-usage-project-list.total' defaultMessage='Total usage' />
            <CuiHelpTipIcon iconType='iInCircle'>
              <FormattedMessage
                id='billing-deployment-usage.tip-icon'
                defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
              />
            </CuiHelpTipIcon>
          </Fragment>
        ),
      },
    },
    {
      label: (
        <FormattedMessage
          id='billing-usage-project-list.solution-label'
          defaultMessage='Solution'
        />
      ),
      render: ({ type }) => getSolutionName(type),
      footer: {
        render: () => null,
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-project-list.total-label'
          defaultMessage='Total (in ECU)'
        />
      ),
      render: ({ total_ecu }) => (
        <CuiElasticConsumptionUnits value={total_ecu} unit='none' dp={4} withSymbol={false} />
      ),
      footer: {
        render: () => (
          <CuiElasticConsumptionUnits
            value={totalProjectCosts}
            unit='none'
            dp={4}
            withSymbol={true}
            symbolPosition='before'
          />
        ),
      },
    },
    {
      actions: true, // additional actions column
      width: '40px',
    },
  ]

  return (
    <Fragment>
      <EuiText size='s'>
        <h3>
          <FormattedMessage
            id='billing-project-usage-list.title'
            defaultMessage='Serverless projects'
          />
        </h3>
      </EuiText>

      <EuiSpacer size='xs' />

      <EuiText size='s'>
        <FormattedMessage
          id='billing-deployment-usage-list.description'
          defaultMessage='All cost units are measured in {ecu}.'
          values={{
            ecu: (
              <DocLink link='billingECU' favorSaasContext={true}>
                <FormattedMessage
                  id='ecu-tooltip.page-ecu-unit'
                  defaultMessage='Elastic Consumption Units (ECU)'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>

      <EuiSpacer size='m' />

      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        css={billingResponsivePanelCss}
        data-test-id='projects-list-usage-table'
      >
        <CuiTable
          rows={visibleItems}
          columns={columns}
          initialLoading={isLoading}
          hasFooterRow={hasAnyProjects}
          responsive={false}
          getRowId={(item) => item.id}
          renderDetailRow={(item) => (
            <DetailsPanel
              instanceId={item.id}
              organizationId={organizationId}
              startDate={filters.startDate}
              endDate={filters.endDate}
            />
          )}
          hasDetailRow={(item) => !!item.id}
          renderDetailButton={true}
          emptyMessage={<EmptyDataPrompt iconType={IconType.TABLE} />}
          renderCustomRowsLast={
            hasAnyProjects
              ? () => (
                  <AggregationTableRowV2<TotalProjectCollapsedCosts, ProjectInstance>
                    onItemSelected={onProjectSelected}
                    totals={collapsedTotals}
                    items={collapsedItems}
                    type='project'
                    renderRow={({ total_ecu, type }) => (
                      <Fragment>
                        <EuiTableRowCell>
                          {Array.isArray(type)
                            ? renderGroupedSolutions(type)
                            : getSolutionName(type)}
                        </EuiTableRowCell>
                        <EuiTableRowCell align='right'>
                          <CuiElasticConsumptionUnits
                            value={total_ecu}
                            unit='none'
                            dp={4}
                            withSymbol={false}
                          />
                        </EuiTableRowCell>
                        <EuiTableRowCell className='euiTableCellContent' />
                      </Fragment>
                    )}
                  />
                )
              : undefined
          }
        />
      </EuiPanel>
    </Fragment>
  )
}

export default ProjectsList
