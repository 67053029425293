/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup, EuiForm, EuiFormRow, EuiSpacer } from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import DeviceOption from '@modules/mfa-management/DeviceOption'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'

import DocLink from '@/components/DocLink' // eslint-disable-line import/no-restricted-paths

const MfaManagement: React.FunctionComponent = () => {
  const [, { webAuthn }] = useFlagsWhenLoaded()
  const { data } = useGetSaasCurrentUserMfaDevicesQuery()
  const isSmsActive = data?.mfa_devices.some(
    (device) => device.device_type === 'SMS' && device.status === 'ACTIVE',
  )

  return (
    <PrivacySensitiveContainer>
      <EuiForm>
        <EuiDescribedFormGroup
          ratio='third'
          title={
            <h3>
              <FormattedMessage
                id='user-settings-profile-mfa'
                defaultMessage='Multifactor authentication'
              />
            </h3>
          }
          titleSize='xs'
          description={
            <FormattedMessage
              id='user-settings-profile-help-text-mfa'
              defaultMessage={`Make your account more secure with multifactor authentication. When logging in to your account, you'll be asked for a verification code using your preferred method to make sure it's you. {docLink}`}
              values={{
                docLink: (
                  <DocLink link='mfaDocLink'>
                    <FormattedMessage id='device-authentication.link' defaultMessage='Learn more' />
                  </DocLink>
                ),
              }}
            />
          }
        >
          <Fragment>
            {webAuthn && (
              <Fragment>
                <EuiFormRow>
                  <DeviceOption type='WEBAUTHN' />
                </EuiFormRow>

                <EuiSpacer size='l' />
              </Fragment>
            )}

            <EuiFormRow>
              <DeviceOption type='GOOGLE' />
            </EuiFormRow>

            <EuiSpacer size='l' />

            <EuiFormRow>
              <DeviceOption type='EMAIL' />
            </EuiFormRow>

            {
              // We don't want to show the SMS enrollment if there is no active SMS device yet,
              // because we want users to move away from SMS MFA.
              isSmsActive && (
                <Fragment>
                  <EuiSpacer size='l' />

                  <EuiFormRow>
                    <DeviceOption type='SMS' />
                  </EuiFormRow>
                </Fragment>
              )
            }
          </Fragment>
        </EuiDescribedFormGroup>
      </EuiForm>
    </PrivacySensitiveContainer>
  )
}

export default MfaManagement
