/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

const DefaultSolutionViews = ['search', 'observability', 'security'] as const

export type DefaultSolutionView = (typeof DefaultSolutionViews)[number]

export function isDefaultSolutionView(value: any): value is DefaultSolutionView {
  return DefaultSolutionViews.includes(value as DefaultSolutionView)
}

export type StepId = 'default-solution-view' | 'deployment-editor' | 'deployment-creation'
