/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useLocation } from 'react-router'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { parse } from 'query-string'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiImage,
  EuiEmptyPrompt,
  EuiLink,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui'

import history from '@modules/utils/history'

import { supportPortalUrl } from '@/apps/userconsole/urls'
import errorImg from '@/files/403_rainy_cloud_light.png'
import errorImgDark from '@/files/403_rainy_cloud_dark.png'

import type { FunctionComponent } from 'react'

const images = {
  LIGHT: errorImg,
  DARK: errorImgDark,
}

const AccessDeniedPanel: FunctionComponent = () => {
  const { colorMode } = useEuiTheme()
  const location = useLocation()
  const { source } = parse(location.search.slice(1))

  return (
    <EuiEmptyPrompt
      titleSize='m'
      icon={<EuiImage size='fullWidth' src={images[colorMode]} alt='' />}
      layout='vertical'
      color='plain'
      paddingSize='l'
      title={
        <h2>
          <FormattedMessage id='http-errors.access-denied.title' defaultMessage='Access denied' />
        </h2>
      }
      body={
        source === `support` ? (
          <Fragment>
            <EuiText size='s'>
              <FormattedMessage
                id='http-errors.access-denied.content-support'
                defaultMessage='To access {support}, please contact your administrator to be added as a support contact.'
                values={{
                  support: <EuiLink href={supportPortalUrl}>support.elastic.co</EuiLink>,
                }}
              />
            </EuiText>
            <EuiSpacer size='s' />
            <EuiText size='s'>
              <FormattedMessage
                id='http-errors.access-denied.contact-support'
                defaultMessage='If you need additional assistance, email {support}.'
                values={{
                  support: <EuiLink href='mailto:support@elastic.co'>support@elastic.co</EuiLink>,
                }}
              />
            </EuiText>
          </Fragment>
        ) : (
          <EuiText size='s'>
            <FormattedMessage
              id='http-errors.access-denied.content'
              defaultMessage='Unfortunately, you don’t have permission to access this page. Contact your administrator for help or reach out to us at {support}.'
              values={{
                support: <EuiLink href='mailto:support@elastic.co'>support@elastic.co</EuiLink>,
              }}
            />
          </EuiText>
        )
      }
      actions={[
        <EuiButton
          fill={true}
          onClick={() => history.replace('/')}
          className='http-error-pages-button'
          fullWidth={true}
        >
          <FormattedMessage id='http-errors-pages.home-button' defaultMessage='Go to home' />
        </EuiButton>,
        <EuiButtonEmpty onClick={() => history.goBack()} className='http-error-pages-button'>
          <FormattedMessage id='http-errors-pages.back-button' defaultMessage='Go back' />
        </EuiButtonEmpty>,
      ]}
    />
  )
}

export default AccessDeniedPanel
