/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find, intersection, isEmpty, last, remove } from 'lodash'
import moment from 'moment'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { EuiCode, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import type {
  DeploymentHealth,
  ElasticsearchBlockingIssueElement,
  ElasticsearchResourceInfo,
  RemoteResources,
} from '@modules/cloud-api/v1/types'
import type { EolStatusResponse } from '@modules/ui-types/eolStatus'
import type {
  AllocatorSearchResult,
  AnyResourceInfo,
  AsyncRequestState,
  DynamicSliderInstanceDefinitionParams,
  SliderInstanceType,
  StackDeployment,
} from '@modules/ui-types'
import { CuiLink } from '@modules/cui/Link'
import { CuiTimeAgo } from '@modules/cui/TimeAgo'
import Feature from '@modules/utils/feature'
import { keysOf } from '@modules/ts-utils'

import EndOfLifeMessage from '@/components/EndOfLifeWarnings/EndOfLifeVersionWarningMessage/EndOfLifeMessage'
import EndOfLifeWarningTitle from '@/components/EndOfLifeWarnings/EndOfLifeWarningTitle'
import { getProblemsFromDeploymentHealthApi } from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/deploymentHealth'
import {
  getLegacyDeploymentProblems,
  getLegacyDeploymentProblemsFromResource,
  shouldRunLegacyHealthReporter,
} from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/legacyDeploymentHealth'
import {
  getInitialPlanFailureFromProblemsList,
  getInitialPlanFailureProblems,
} from '@/lib/healthProblems/stackDeploymentHealth/healthReporters/failedInitialPlanReporter'
import { getConfigForKey, isFeatureActivated } from '@/store'
import {
  isStopping,
  isStopped,
  isRestarting,
  isForceRestarting,
  hasOngoingResourceConfigurationChange,
  getPlanLog,
  getPendingSource,
} from '@/lib/stackDeployments/selectors/configurationChanges'
import {
  isPlanSystemInitiated,
  getVersion,
  getResourceVersion,
  getPlanInfo,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  isPendingInitialSnapshot,
  hasSuspendedSnapshots,
  hasSlm,
  hasScheduledSnapshot,
  hasRecentSnapshotSuccess,
  hasLatestSnapshotSuccess,
  hasHealthySnapshots,
  hasEnabledSnapshots,
  getScheduledSnapshotTime,
  getLatestSnapshotSuccess,
} from '@/lib/stackDeployments/selectors/snapshots'
import {
  isLocked,
  isHiddenResource,
  isHidden,
  getHiddenTimestamp,
} from '@/lib/stackDeployments/selectors/metadata'
import {
  hasHealthyResourcePlan,
  hasHealthyEsPlan,
  hasCreatePlan,
} from '@/lib/stackDeployments/selectors/configurationChangeHealth'
import { hasHealthyRemotes, getRemotes } from '@/lib/stackDeployments/selectors/remotes'
import {
  hasBlockingIssues,
  getBlockingIssues,
} from '@/lib/stackDeployments/selectors/blockingIssues'
import { getLastPlanAttempt } from '@/lib/stackDeployments/selectors/configurationChangeAttempts'
import { getEsNodeConfigurationsFromGet } from '@/lib/stackDeployments/selectors/topologyElements'
import {
  shouldUpgradeSliderVersion,
  hasMismatchingEsVersions,
  getExpectedSliderVersion,
} from '@/lib/stackDeployments/upgradesToSliders'
import {
  isSystemUpgradeRecommended,
  getRecommendedVersion,
} from '@/lib/stackDeployments/upgradesToSystem'
import { isSliderInstanceType, getSupportedSliderInstanceTypes } from '@/lib/sliders/support'
import { getSliderPrettyName } from '@/lib/sliders/messages'
import { getSliderIconType } from '@/lib/sliders/icons'

import { describePlanAttemptSource, describePlanAttemptStep } from '../../plan'
import {
  deploymentActivityUrl,
  deploymentEditUrl,
  deploymentsUrl,
  deploymentUrl,
  securityUrl,
  sliderActivityUrl,
} from '../../urlBuilder'
import { isCurrentPath } from '../../urls'
import CancelPlanButton from '../../../components/StackDeploymentConfigurationChange/CancelPlanButton'
import DocLink from '../../../components/DocLink'
import { hasInconsistentUserSettings } from '../../stackDeployments/userSettings'
import { getFirstEolResourceForDeployment, getSeverityLevel } from '../../eolStatus'
import { getEuiHealthColor, prepareProblems } from '../problems'

import { parseConfigurationChangeError } from './configurationChangeError'

import type { EuiHealthColor, PreparedProblems, Problem, SeverityLevel } from '../problems'
import type { ReactElement, ReactNode } from 'react'

type BlockingErrorDescription = {
  matches: (description: string) => boolean
  id: string
  level?: SeverityLevel
  message?: ReactElement
}

const blockingErrors: BlockingErrorDescription[] = [
  {
    matches: (description) => description.includes(`index preparing to close`),
    id: `index-preparing-to-close`,
    level: `warning`,
  },
  {
    matches: (description) => description === `index read-only / allow delete (api)`,
    id: `readonly-index`,
    level: `warning`,
    message: (
      <FormattedMessage
        id='deployment-health-problems.es-readonly-index'
        defaultMessage='An Elasticsearch index is in a read-only state and only allows deletes'
      />
    ),
  },
  {
    matches: (description) => description === `no master`,
    id: `no-master`,
    message: (
      <FormattedMessage
        id='deployment-health-problems.es-no-master'
        defaultMessage='No master node reported'
      />
    ),
  },
  {
    matches: (description) => description === `state not recovered / initialized`,
    id: `state-not-recovered`,
    message: (
      <FormattedMessage
        id='deployment-health-problems.es-state-not-recovered'
        defaultMessage='Instance state not recovered or initialized'
      />
    ),
  },
]

/**
 * getDeploymentHealthProblems collects a list of Problems related to a deployment from a number of different contexts.
 * Currently, we are calculating health here mostly from the deployment API response. However, this
 * will gradually be replaced with details provided by the deployment health API as more of these problems
 * are identified and reported there.
 *
 * The deploymentHealthRequest and health have to be optional because we use this same method in the
 * search and individual deployment health banner context. Follow-up work should split the
 * two contexts, so that they can be handled separately.
 *
 * This function should be split and this issue should be resolved with https://github.com/elastic/cloud/issues/98478
 */
export function getDeploymentHealthProblems({
  deployment,
  deploymentHealthRequest,
  health,
  eolStatus,
  cancelPlanRequests,
  hideLinks,
  hideActivityBits,
  deploymentAllocators,
  ccsSettings,
  onGettingStartedPage,
  hideHealthApiResults = false,
}: {
  deployment: StackDeployment
  deploymentHealthRequest?: AsyncRequestState
  health?: DeploymentHealth | null
  eolStatus: EolStatusResponse | null
  hideLinks?: boolean
  hideActivityBits?: boolean
  cancelPlanRequests?: {
    [sliderInstanceType: string]: AsyncRequestState
  }
  deploymentAllocators?: AllocatorSearchResult[]
  ccsSettings?: RemoteResources | null
  onGettingStartedPage?: boolean
  hideHealthApiResults?: boolean
}): PreparedProblems {
  const problems: Problem[] = []

  // no problems if there's no deployment to base problems off of
  if (!deployment) {
    return prepareProblems(problems)
  }

  const { resources } = deployment
  const [esResource] = resources.elasticsearch
  const [kibanaResource] = resources.kibana
  const [apmResource] = resources.apm
  const [appsearchResource] = resources.appsearch
  const mainResource = esResource || kibanaResource || apmResource || appsearchResource

  // no problems if there's no clusters to base problems off of
  if (!mainResource) {
    return prepareProblems(problems)
  }

  const hideActivity = hideActivityBits || hideLinks

  // creating the deployment shouldn't show up as an error nor be hidden
  const creationProblems = getCreationProblems({
    deployment,
    hideLinks: hideActivity,
    onGettingStartedPage,
  })

  if (creationProblems.length > 0) {
    return prepareProblems(creationProblems)
  }

  const creatingDeployment = hasCreatePlan({ deployment })
  // in some components we want to ignore the results from the healthApi, otherwise we end up with mixed results depending on if the user has
  // already visited the deployments page or not (deployments page is where we call the health api). See https://elasticco.atlassian.net/browse/CP-6084
  let problemsFromDeploymentHealthApi = getProblemsFromDeploymentHealthApi(
    hideHealthApiResults ? null : health,
  )

  if (creatingDeployment) {
    // Suppress the initial failure message if the deployment is still being created
    problemsFromDeploymentHealthApi = problemsFromDeploymentHealthApi.filter(
      (problem) => problem.id !== `failed-initial-plan`,
    )
  }

  // Regardless of whether we are using the legacy health reporter or the new health api: if we
  // ever have an `initial-plan-failure` problem, we only want to report that one to the user.
  const initialPlanFailures = shouldRunLegacyHealthReporter(health, deploymentHealthRequest)
    ? getInitialPlanFailureProblems({ deployment })
    : getInitialPlanFailureFromProblemsList(problemsFromDeploymentHealthApi)

  if (!isEmpty(initialPlanFailures)) {
    return prepareProblems(initialPlanFailures)
  }

  problems.push(...problemsFromDeploymentHealthApi)

  // generate resource-level problems
  const sliderInstanceTypes = getSupportedSliderInstanceTypes()
  const resourceTypes = keysOf(resources)
  const supportedResourceTypes = intersection(resourceTypes, sliderInstanceTypes)

  for (const sliderInstanceType of supportedResourceTypes) {
    const [resource] = resources[sliderInstanceType]

    if (!resource) {
      continue // not every possible instance type will be populated with a resource
    }

    problems.push(
      ...getConfigurationChangeProblems({
        deployment,
        resource,
        sliderInstanceType,
        cancelPlanRequest: cancelPlanRequests && cancelPlanRequests[sliderInstanceType],
        hideActivity,
      }),
    )

    problems.push(
      ...getLegacyDeploymentProblemsFromResource(
        deployment,
        esResource!,
        sliderInstanceType,
        health,
        deploymentHealthRequest,
      ),
    )

    if (sliderInstanceType === `elasticsearch`) {
      problems.push(
        ...getElasticsearchHealthProblems({
          deployment,
          resource: esResource!,
          hideLinks,
          eolStatus,
        }),
      )
    } else {
      problems.push(
        ...getSliderHealthProblems({
          deployment,
          sliderInstanceType,
          hideLinks,
        }),
      )
    }
  }

  if (isHidden({ deployment })) {
    const hiddenTimestamp = getHiddenTimestamp({ deployment })

    problems.push({
      sticky: true,
      kind: `deployment`,
      id: `deployment-hidden`,
      level: `warning`,
      iconType: `eyeClosed`,
      message: hiddenTimestamp ? (
        <FormattedMessage
          id='deployment-health-problems.deployment-hidden-since'
          defaultMessage='Deployment hidden {when}'
          values={{
            when: <CuiTimeAgo date={moment.utc(hiddenTimestamp)} shouldCapitalize={false} />,
          }}
        />
      ) : (
        <FormattedMessage
          id='deployment-health-problems.deployment-hidden'
          defaultMessage='Deployment is hidden'
        />
      ),
      'data-test-id': 'clusterInfo-clusterHidden',
    })
  }

  if (isLocked({ deployment })) {
    problems.push({
      sticky: true,
      kind: `deployment`,
      id: `deployment-lock-enabled`,
      level: `warning`,
      iconType: `lock`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.deployment-lock-enabled'
          defaultMessage='Configuration changes have been temporarily disabled while we perform maintenance work'
        />
      ),
    })
  }

  if (ccsSettings) {
    problems.push(...getCCSProblems(ccsSettings, deployment))
  }

  problems.push(
    ...getLegacyDeploymentProblems(health, deploymentHealthRequest, deploymentAllocators),
  )

  return prepareProblems(problems)
}

function getCCSProblems(ccsSettings: RemoteResources, deployment: StackDeployment): Problem[] {
  const { resources } = ccsSettings
  const problems: Problem[] = []

  const messages = {
    trustManagement: (
      <FormattedMessage
        id='deployment-health-problems.trust-management'
        defaultMessage='Trust management'
      />
    ),
    deployments: (
      <Link to={deploymentsUrl()}>
        <FormattedMessage
          id='deployment-health-problems.deployment-page'
          defaultMessage='Deployments'
        />
      </Link>
    ),
    remoteClusterCompatibility: (
      <DocLink link='remoteClusterCompatibility'>
        <FormattedMessage
          id='deployment-health-problems.compatiblity-matrix'
          defaultMessage='compatible versions'
        />
      </DocLink>
    ),
    enableCCS: (
      <DocLink link='enableCCS'>
        <FormattedMessage
          id='deployment-health-problems.enable-ccs-learn-more'
          defaultMessage='Learn more'
        />
      </DocLink>
    ),
  }

  resources.forEach((resource) => {
    if (resource.info) {
      const { healthy, connected, compatible, trusted, trusted_back } = resource.info

      const numberOfIssues = Object.keys(resource.info).filter((key) => !resource.info![key])

      if (numberOfIssues.length > 0) {
        if (numberOfIssues.length > 1) {
          // If there's a number of things wrong, show them a big combined warning message
          problems.push({
            'data-test-id': `remote-multi-issues`,
            kind: `deployment`,
            id: `deployment-remote-multi-issues`,
            level: `warning`,
            message: (
              <FormattedMessage
                id='deployment-health-problems.remote-multi-issues'
                defaultMessage="Connection between this deployment and {remoteName} could not be established. Verify that the trust goes both ways (see this deployment's {thisTrustManagement} and {remoteName}'s {remoteTrustManagement}), that both deployments are healthy (see {deploymentsLink}), and that they are {compatibleVersions}"
                values={{
                  remoteName: <EuiCode>{resource.alias}</EuiCode>,
                  thisTrustManagement: (
                    <Link to={securityUrl(deployment.id)}>{messages.trustManagement}</Link>
                  ),
                  remoteTrustManagement: (
                    <Link to={securityUrl(resource.deployment_id)}>{messages.trustManagement}</Link>
                  ),
                  deploymentsLink: messages.deployments,
                  compatibleVersions: messages.remoteClusterCompatibility,
                }}
              />
            ),
          })
        } else {
          // If there's only one issue, show a specific error
          if (!healthy) {
            problems.push({
              'data-test-id': `remote-unhealthy`,
              kind: `deployment`,
              id: `deployment-remote-unhealthy`,
              level: `warning`,
              message: (
                <FormattedMessage
                  id='deployment-health-problems.remote-unhealthy'
                  defaultMessage='Connection between this deployment and {remoteName} could not be established. Verify that both deployments are healthy (see {deploymentsLink}).'
                  values={{
                    remoteName: <EuiCode>{resource.alias}</EuiCode>,
                    deploymentsLink: messages.deployments,
                  }}
                />
              ),
            })
          }

          if (!connected) {
            problems.push({
              'data-test-id': `remote-not-connected`,
              kind: `deployment`,
              id: `deployment-remote-not-connected`,
              level: `warning`,
              message: (
                <FormattedMessage
                  id='deployment-health-problems.remote-not-connected'
                  defaultMessage='Connection between this deployment and {remoteName} could not be established. Verify the configuration. {learnMore}'
                  values={{
                    remoteName: <EuiCode>{resource.alias}</EuiCode>,
                    learnMore: messages.enableCCS,
                  }}
                />
              ),
            })
          }
        }

        if (!compatible) {
          problems.push({
            'data-test-id': `remote-incompatible`,
            kind: `deployment`,
            id: `deployment-remote-incompatible`,
            level: `warning`,
            message: (
              <FormattedMessage
                id='deployment-health-problems.remote-incompatible'
                defaultMessage='Connection between this deployment and {remoteName} could not be established. The versions are not compatible. See the {compatibilityMatrix} chart.'
                values={{
                  remoteName: <EuiCode>{resource.alias}</EuiCode>,
                  compatibilityMatrix: messages.remoteClusterCompatibility,
                }}
              />
            ),
          })
        }

        if (!trusted) {
          problems.push({
            'data-test-id': `remote-untrusted`,
            kind: `deployment`,
            id: `deployment-remote-untrusted`,
            level: `warning`,
            message: (
              <FormattedMessage
                id='deployment-health-problems.remote-untrusted'
                defaultMessage='Connection between this deployment and {remoteName} could not be established. Make sure that this deployment trusts {remoteName} in {trustManagement}.'
                values={{
                  remoteName: <EuiCode>{resource.alias}</EuiCode>,
                  trustManagement: (
                    <Link to={securityUrl(deployment.id)}>{messages.trustManagement}</Link>
                  ),
                }}
              />
            ),
          })
        }

        if (!trusted_back) {
          problems.push({
            'data-test-id': `remote-untrusted-back`,
            kind: `deployment`,
            id: `deployment-remote-untrusted-back`,
            level: `warning`,
            message: (
              <FormattedMessage
                id='deployment-health-problems.remote-untrusted-back'
                defaultMessage='Connection between this deployment and {remoteName} could not be established. Make sure that {remoteName} trusts this deployment in {trustManagement}.'
                values={{
                  remoteName: <EuiCode>{resource.alias}</EuiCode>,
                  trustManagement: (
                    <Link to={securityUrl(resource.deployment_id)}>{messages.trustManagement}</Link>
                  ),
                }}
              />
            ),
          })
        }
      }
    }
  })

  return problems
}

function getCreationProblems({
  deployment,
  onGettingStartedPage,
}: {
  deployment?: StackDeployment
  hideLinks?: boolean
  onGettingStartedPage?: boolean
}): Problem[] {
  const problems: Problem[] = []

  if (!deployment) {
    return problems
  }

  // #115444 - hasCreatePlan() returns false towards the end of the creation process
  const creating = hasCreatePlan({ deployment }) || onGettingStartedPage

  if (!creating) {
    return problems
  }

  // #115444 - if it's being created, no warnings or failures should be shown.
  if (!hasHealthyEsPlan({ deployment }) && !onGettingStartedPage) {
    return problems
  }

  // a new deployment has no business being `hidden`, thus we'll assume create failed.
  if (isHidden({ deployment })) {
    return problems
  }

  problems.push({
    kind: `deployment`,
    id: `deployment-being-created`,
    'data-test-id': `deployment-being-created`,
    level: `info`,
    iconType: `clock`,
    title: (
      <FormattedMessage
        id='deployment-health-problems.deployment-being-created-title'
        defaultMessage='Launching your deployment'
      />
    ),
    titleSummary: (
      <FormattedMessage
        id='deployment-health-problems.deployment-being-created-summary'
        defaultMessage='Being created'
      />
    ),
    message: (
      <FormattedMessage
        id='deployment-health-problems.deployment-being-created'
        defaultMessage='(~5 min)'
      />
    ),
  })

  return problems
}

function getConfigurationChangeProblems({
  deployment,
  resource,
  sliderInstanceType,
  cancelPlanRequest,
  hideActivity,
}: {
  deployment: StackDeployment
  resource: AnyResourceInfo
  sliderInstanceType: SliderInstanceType
  cancelPlanRequest?: AsyncRequestState
  hideActivity?: boolean
}) {
  const problems: Problem[] = []

  if (!resource) {
    return problems
  }

  const clusterIconType = getClusterIcon(sliderInstanceType)
  const changeset = getConfigurationChangeset({ resource, sliderInstanceType, cancelPlanRequest })

  if (changeset === null) {
    return problems
  }

  const title = (
    <span data-test-id='configuration-change-in-progress'>
      <FormattedMessage
        id='deployment-health-problems.changing-deployment-configuration-title'
        defaultMessage='Configuration change in progress'
      />
    </span>
  )

  const titleSummary = (
    <FormattedMessage
      id='deployment-health-problems.changing-deployment-configuration-summary'
      defaultMessage='Changing configuration'
    />
  )

  const gotoActivity = (
    <CuiLink to={getClusterActivityLink({ deployment, sliderInstanceType })}>
      <FormattedMessage
        id='deployment-health-problems.go-to-activity'
        defaultMessage='Go to Activity'
      />
    </CuiLink>
  )

  const pendingPlan = getPlanInfo({ resource, state: 'pending' })
  const isSystemInitiated = isPlanSystemInitiated({ planAttempt: pendingPlan })
  const isAdminconsole = getConfigForKey(`APP_NAME`) === `adminconsole`
  // in https://github.com/elastic/cloud/issues/103228 decided to hide the cancel button for system initiated plans on the UC
  // have to take into account UC specifically, otherwise all plans on ECE would be not cancellable
  const showCancelButton = isAdminconsole || !isSystemInitiated

  const message = (
    <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
      <EuiFlexItem grow={false}>
        <div data-test-id='pendingPlan-message'>{changeset.description}</div>
      </EuiFlexItem>

      {hideActivity || (
        <Fragment>
          <EuiFlexItem grow={false}>{gotoActivity}</EuiFlexItem>
          {showCancelButton && (
            <EuiFlexItem grow={false}>
              <CancelPlanButton
                deployment={deployment}
                resource={resource}
                resourceType={sliderInstanceType}
              />
            </EuiFlexItem>
          )}
        </Fragment>
      )}
    </EuiFlexGroup>
  )

  problems.push({
    kind: sliderInstanceType,
    id: changeset.id,
    level: `info`,
    iconType: clusterIconType,
    spinner: true,
    hidden: hideActivity,
    title,
    titleSummary,
    message,
    'data-test-id': changeset[`data-test-id`],
  })

  return problems
}

function getConfigurationChangeset({
  resource,
  sliderInstanceType,
  cancelPlanRequest,
}: {
  resource: AnyResourceInfo
  sliderInstanceType: SliderInstanceType
  cancelPlanRequest?: AsyncRequestState
}): {
  id: string
  description: ReactNode
  'data-test-id': string
} | null {
  const version = getResourceVersion({ resource })

  const clusterKind = getClusterKindTitle({ sliderInstanceType, version })

  if (isForceRestarting({ resource })) {
    return {
      id: `force-restarting-${sliderInstanceType}`,
      description: (
        <FormattedMessage
          id='deployment-health-problems.force-restarting-deployment'
          defaultMessage='Force restarting {clusterKind}'
          values={{ clusterKind }}
        />
      ),
      'data-test-id': `deploymentStatus-forceRestartingPlan`,
    }
  }

  if (isRestarting({ resource })) {
    return {
      id: `restarting-${sliderInstanceType}`,
      description: (
        <FormattedMessage
          id='deployment-health-problems.restarting-deployment'
          defaultMessage='Restarting {clusterKind}'
          values={{ clusterKind }}
        />
      ),
      'data-test-id': `deploymentStatus-forceRestartingPlan`,
    }
  }

  if (isStopping({ resource })) {
    return {
      id: `terminating-${sliderInstanceType}`,
      description: (
        <FormattedMessage
          id='deployment-health-problems.terminating-deployment'
          defaultMessage='Terminating {clusterKind}'
          values={{ clusterKind }}
          data-test-id='terminating-deployment'
        />
      ),
      'data-test-id': `deploymentStatus-stoppingPlan`,
    }
  }

  if (hasOngoingResourceConfigurationChange({ resource })) {
    return {
      id: `changing-${sliderInstanceType}-configuration-now`,
      description: describePendingChange(),
      'data-test-id': `pendingPlan-progress`,
    }
  }

  return null

  function describePendingChange() {
    const isCancelled = cancelPlanRequest && cancelPlanRequest.isDone && !cancelPlanRequest.error

    if (isCancelled) {
      return (
        <FormattedMessage
          id='deployment-health-problems.cancelling-changes'
          defaultMessage='Cancelling changes to {clusterKind}'
          values={{ clusterKind }}
        />
      )
    }

    const plan = getPlanInfo({ resource, state: `pending` })

    if (
      sliderInstanceType === `elasticsearch` &&
      //@ts-ignore we specify in the condition above that this is elasticsearch, so the force_bucket_management_migration field will exist
      plan?.plan?.transient?.plan_configuration?.force_bucket_management_migration
    ) {
      if (resource.region.startsWith('azure')) {
        return (
          <FormattedMessage
            id='explain-changes.azure-bucket-migration'
            defaultMessage='Enable dedicated snapshot repository for this deployment'
          />
        )
      }

      return (
        <FormattedMessage
          id='explain-changes.aws-gcp-bucket-migration'
          defaultMessage='Enable snapshot repository isolation'
        />
      )
    }

    const messages = getPlanLog({ resource, state: `pending` })
    const lastMessage = last(messages)

    if (lastMessage) {
      const stepInfo = describePlanAttemptStep(lastMessage)
      return stepInfo.value
    }

    const pendingSource = getPendingSource({ resource })

    if (pendingSource) {
      const describedSource = describePlanAttemptSource({ source: pendingSource })

      if (describedSource !== null) {
        return describedSource
      }
    }

    return (
      <FormattedMessage
        id='deployment-health-problems.applying-changes'
        defaultMessage='Applying changes to {clusterKind}'
        values={{ clusterKind }}
      />
    )
  }
}

function getClusterActivityLink({
  deployment,
  sliderInstanceType,
}: {
  deployment: StackDeployment
  sliderInstanceType: SliderInstanceType
}) {
  const { id } = deployment

  if (isSliderInstanceType(sliderInstanceType)) {
    return sliderActivityUrl(id, sliderInstanceType)
  }

  return deploymentActivityUrl(id)
}

function getClusterIcon(sliderInstanceType: SliderInstanceType) {
  const defaultIcon = `logoElasticsearch`
  return getSliderIconType({ sliderInstanceType }) || defaultIcon
}

function getClusterKindTitle({
  sliderInstanceType,
  ...dynamicSliderInstanceDefinitionParams
}: DynamicSliderInstanceDefinitionParams & { sliderInstanceType: SliderInstanceType }) {
  if (sliderInstanceType !== `elasticsearch` && isSliderInstanceType(sliderInstanceType)) {
    return (
      <FormattedMessage
        {...getSliderPrettyName({ sliderInstanceType, ...dynamicSliderInstanceDefinitionParams })}
      />
    )
  }

  return (
    <FormattedMessage
      id='deployment-health-problems.deployment-cluster-kind'
      defaultMessage='this deployment'
    />
  )
}

function getElasticsearchHealthProblems({
  deployment,
  resource,
  eolStatus,
  hideLinks,
}: {
  deployment: StackDeployment
  resource: ElasticsearchResourceInfo
  eolStatus?: EolStatusResponse | null
  hideLinks?: boolean
}): Problem[] {
  const problems: Problem[] = []
  const hidePlanDetails = isFeatureActivated(Feature.hidePlanDetails)
  const { id } = deployment

  if (hasOngoingResourceConfigurationChange({ resource })) {
    return problems
  }

  if (isStopped({ resource })) {
    problems.push({
      kind: `deployment`,
      id: `deployment-terminated`,
      level: `warning`,
      iconType: `stopFilled`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.deployment-terminated'
          defaultMessage='Deployment is terminated'
        />
      ),
    })
  }

  if (hasMismatchingEsVersions({ deployment })) {
    problems.push({
      kind: `elasticsearch`,
      id: `es-version-diff`,
      level: `danger`,
      'data-test-id': `version-diff`,
      message: (
        <FormattedMessage
          id='stack-deployment-health-problems.version-diff-message'
          defaultMessage='This deployment has a version mismatch in the Elasticsearch instances.'
        />
      ),
      helpText: (
        <FormattedMessage
          id='stack-deployment-health-problems.version-diff-help'
          defaultMessage="This might be from a failed upgrade. Try again, and if it's still a problem, contact support."
        />
      ),
    })
  }

  const nodeConfigurations = getEsNodeConfigurationsFromGet({ deployment })
  const inconsistentUserSettings = hasInconsistentUserSettings({ nodeConfigurations })

  if (inconsistentUserSettings) {
    problems.push({
      kind: `elasticsearch`,
      id: `es-user-settings-diff`,
      level: `warning`,
      'data-test-id': `user-settings-diff`,
      message: (
        <FormattedMessage
          id='stack-deployment-health-problems.user-settings-diff-message'
          defaultMessage='User settings are different across Elasticsearch instances. {learnMore}'
          values={{
            learnMore: (
              <DocLink link='esUserSettingsDocLink'>
                <FormattedMessage
                  id='stack-deployment-health-problems.user-settings.learn-more'
                  defaultMessage='Learn more'
                />
              </DocLink>
            ),
          }}
        />
      ),
      helpText: (
        <FormattedMessage
          id='stack-deployment-health-problems.user-settings-diff-help'
          defaultMessage='This could lead to issues with your deployment. {editLink}'
          values={{
            editLink: (
              <CuiLink to={deploymentEditUrl(deployment.id)}>
                <FormattedMessage
                  id='stack-deployment-health-problems.go-to-edit-page'
                  defaultMessage='Go to Edit page'
                />
              </CuiLink>
            ),
          }}
        />
      ),
    })
  }

  if (!hasHealthyResourcePlan({ resource })) {
    problems.push({
      stickyLast: true,
      kind: `elasticsearch`,
      id: `es-config-change-failed`,
      level: `warning`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.es-config-change-failed'
          defaultMessage='Latest change to Elasticsearch configuration failed'
        />
      ),
      tooltip: hidePlanDetails
        ? null
        : getLatestPlanFailureMessage({ deployment, resource, resourceType: `elasticsearch` }),
    })
  }

  const snapshotProblems = getSnapshotProblems({ resource })

  problems.push(...snapshotProblems)
  problems.push(...getEsBlockingProblems({ resource }))

  if (hasHealthyRemotes({ resource })) {
    const remotes = getRemotes({ resource })
    const problemRemoteIds = remotes
      .filter((remote) => !remote.info?.compatible)
      .map((remote) => remote.deployment_id)

    if (problemRemoteIds.length) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-remote-cluster-compatibility-issues`,
        level: `warning`,
        iconType: `offline`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.remote-deployment-compatibility-issues'
            defaultMessage='Incompatible remote {amount, plural, one {deployment} other {deployments}}: {deployments} must be upgraded to include in search'
            values={{
              amount: problemRemoteIds.length,
              deployments: (
                <span>
                  {problemRemoteIds.map((deploymentId) => (
                    <EuiCode key={deploymentId}>
                      {hideLinks ? (
                        deploymentId.slice(0, 6)
                      ) : (
                        <CuiLink to={deploymentUrl(deploymentId)}>
                          {deploymentId.slice(0, 6)}
                        </CuiLink>
                      )}
                    </EuiCode>
                  ))}
                </span>
              ),
            }}
          />
        ),
      })
    }
  }

  if (eolStatus) {
    const resourceEolStatus = getFirstEolResourceForDeployment({ deployment, eolStatus })

    if (resourceEolStatus) {
      const overviewUrl = deploymentUrl(id)
      problems.push({
        'data-test-id': 'deployment-eol-warning',
        kind: `deployment`,
        id: `eol-upgrade-recommended`,
        level: getSeverityLevel(resourceEolStatus),
        title: <EndOfLifeWarningTitle resourceEolStatus={resourceEolStatus} />,
        iconType: `alert`,
        dismissible: true,
        message: (
          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false}>
              <div>
                <EndOfLifeMessage resourceEolStatus={resourceEolStatus} showLink={true} />
              </div>
            </EuiFlexItem>

            {!hideLinks && !isCurrentPath(overviewUrl) && (
              <EuiFlexItem grow={false}>
                <CuiLink to={overviewUrl} style={{ whiteSpace: `nowrap` }}>
                  <FormattedMessage
                    id='deployment-health-problems.eol-go-to-overview'
                    defaultMessage='Go to the Overview page to upgrade.'
                  />
                </CuiLink>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        ),
      })
    }
  }

  if (isSystemUpgradeRecommended({ deployment })) {
    const recommendedVersion = getRecommendedVersion()
    const overviewUrl = deploymentUrl(id)

    problems.push({
      kind: `elasticsearch`,
      id: `es-system-upgrade-recommended`,
      level: `info`,
      iconType: `alert`,
      message: (
        <EuiFlexGroup gutterSize='s' alignItems='center'>
          <EuiFlexItem grow={false}>
            <FormattedMessage
              id='deployment-health-problems.es-system-upgrade-recommended'
              defaultMessage='Upgrade Elasticsearch to version {recommendedVersion} or above'
              values={{ recommendedVersion }}
            />
          </EuiFlexItem>

          {!hideLinks && !isCurrentPath(overviewUrl) && (
            <EuiFlexItem grow={false}>
              <CuiLink to={overviewUrl}>
                <FormattedMessage
                  id='deployment-health-problems.deployment-upgrade-link'
                  defaultMessage='Go to the Overview page to upgrade'
                />
              </CuiLink>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      ),
    })
  }

  // these problems come from different sources.
  // "no masters overall" is more obvious than "no master according to (every instance)"
  if (find(problems, { id: `es-master-node-none` })) {
    remove(problems, { id: `es-cluster-locking-error-no-master` })
  }

  return problems
}

function getSliderHealthProblems({
  deployment,
  sliderInstanceType,
  hideLinks,
}: {
  deployment: StackDeployment
  sliderInstanceType: SliderInstanceType
  hideLinks?: boolean
}): Problem[] {
  const hidePlanDetails = isFeatureActivated(Feature.hidePlanDetails)
  const problems: Problem[] = []

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const resource = deployment.resources[sliderInstanceType][0]!

  if (hasOngoingResourceConfigurationChange({ resource })) {
    return problems
  }

  const version = getVersion({ deployment })

  const prettyName = <FormattedMessage {...getSliderPrettyName({ sliderInstanceType, version })} />

  if (isHiddenResource({ resource })) {
    problems.push({
      kind: sliderInstanceType,
      id: `${sliderInstanceType}-hidden`,
      level: `warning`,
      iconType: `eyeClosed`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.slider-hidden'
          defaultMessage='{prettyName} is hidden'
          values={{ prettyName }}
        />
      ),
    })
  }

  const shouldUpgrade = shouldUpgradeSliderVersion({ deployment, sliderInstanceType })

  if (shouldUpgrade) {
    const overviewUrl = deploymentUrl(deployment.id)

    problems.push({
      kind: sliderInstanceType,
      id: `${sliderInstanceType}-upgrade-to-match-es`,
      level: `info`,
      iconType: `alert`,
      message: (
        <EuiFlexGroup gutterSize='s' alignItems='center'>
          <EuiFlexItem grow={false}>
            <FormattedMessage
              id='deployment-health-problems.slider-needs-to-be-upgraded'
              defaultMessage='Deployment upgrade failed. Could not upgrade {prettyName} to {recommendedVersion}.'
              values={{
                prettyName,
                recommendedVersion: getExpectedSliderVersion({ deployment }),
              }}
            />
          </EuiFlexItem>

          {!hideLinks && !isCurrentPath(overviewUrl) && (
            <EuiFlexItem grow={false}>
              <CuiLink to={overviewUrl}>
                <FormattedMessage
                  id='deployment-health-problems.deployment-upgrade-link'
                  defaultMessage='Go to the Overview page to upgrade'
                />
              </CuiLink>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      ),
    })
  }

  if (!hasHealthyResourcePlan({ resource })) {
    problems.push({
      stickyLast: true,
      kind: sliderInstanceType,
      id: `${sliderInstanceType}-config-change-failed`,
      level: `warning`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.slider-config-change-failed'
          defaultMessage='Latest change to {prettyName} configuration failed'
          values={{ prettyName }}
        />
      ),
      tooltip: hidePlanDetails
        ? null
        : getLatestPlanFailureMessage({ deployment, resource, resourceType: sliderInstanceType }),
    })
  }

  return problems
}

function getEsBlockingProblems({ resource }: { resource: ElasticsearchResourceInfo }): Problem[] {
  const problems: Problem[] = []

  if (!hasBlockingIssues({ resource })) {
    return problems
  }

  const clusterBlocks = getBlockingIssues({ resource, type: 'global' })
  const indexBlocks = getBlockingIssues({ resource, type: 'index' })

  walkBlocks(clusterBlocks, `cluster`)
  walkBlocks(indexBlocks, `index`)

  return problems

  function walkBlocks(blocks: ElasticsearchBlockingIssueElement[], type) {
    blocks.forEach((block, index) => {
      const { description } = block

      const blockError: BlockingErrorDescription | undefined = find(
        blockingErrors,
        (blockingError) => blockingError.matches(description),
      )

      const blockSuffixId = blockError ? blockError.id : index
      const blockMessage = (blockError && blockError.message) || description
      const blockLevel = (blockError && blockError.level) || `danger`

      // Disk issues come reported as cluster blocking issues by the deployment details api, and there
      // isn't any discernible way to accurately distinguish them other than checking the blockMessage
      // for a 'disk contains' string.
      //
      // We do so to make sure that for disk related issues we keep the same id name as the ones being
      // reported by the health api, so that they will be deduped and only the ones shown in the table (if any)
      // will have precedence over the ones in the banner.
      //
      /* This is an example response from the API
          "cluster_blocking_issues" : {
            "healthy" : false,
            "blocks" : [
              {
                "description" : "disk usage exceeded flood-stage watermark, index has read-only-allow-delete block [.apm-agent-configuration]",
                "level" : "index"
              },
          }
       */
      const blockId =
        typeof blockMessage === 'string' && blockMessage.includes('disk usage')
          ? `disk`
          : `es-${type}-locked-error-${blockSuffixId}`

      problems.push({
        kind: `elasticsearch`,
        id: blockId,
        iconType: `error`,
        level: blockLevel,
        message: blockMessage,
        helpText: isEmpty(blocks) ? null : (
          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false}>
              <FormattedMessage
                id='deployment-health-problems.es-blocks-found'
                defaultMessage='Blocks found'
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
      })
    })
  }
}

function getSnapshotProblems({ resource }: { resource: ElasticsearchResourceInfo }): Problem[] {
  const problems: Problem[] = []

  if (hasHealthySnapshots({ resource })) {
    return problems
  }

  const latestSuccess = getLatestSnapshotSuccess({ resource })

  if (!hasEnabledSnapshots({ resource })) {
    if (getConfigForKey(`APP_PLATFORM`) === `ece`) {
      return problems
    }

    problems.push({
      kind: `elasticsearch`,
      id: `es-snapshots-are-disabled`,
      level: `warning`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.es-snapshots-are-disabled'
          defaultMessage='Snapshots are disabled'
        />
      ),
    })
  } else if (hasSlm({ resource }) && hasSuspendedSnapshots({ resource })) {
    problems.push({
      kind: `elasticsearch`,
      id: `es-snapshots-slm-unexpectedly-suspended`,
      level: `warning`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.es-snapshots-slm-unexpectedly-suspended'
          defaultMessage='SLM unexpectedly disabled'
        />
      ),
    })
  } else if (!hasRecentSnapshotSuccess({ resource })) {
    if (latestSuccess) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-snapshots-out-of-date-with-last-success`,
        level: `warning`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-snapshots-out-of-date-with-last-success'
            defaultMessage='Last successful snapshot was {lastSuccess}'
            values={{
              lastSuccess: <CuiTimeAgo date={moment(latestSuccess)} shouldCapitalize={false} />,
            }}
          />
        ),
      })
    } else if (isPendingInitialSnapshot({ resource })) {
      if (hasScheduledSnapshot({ resource })) {
        problems.push({
          kind: `elasticsearch`,
          id: `es-no-snapshots-but-hopefully-soon`,
          level: `info`,
          iconType: `clock`,
          message: (
            <FormattedMessage
              id='deployment-health-problems.es-initial-snapshot-scheduled'
              defaultMessage='Initial snapshot scheduled for {nextSnapshotAt}'
              values={{
                nextSnapshotAt: (
                  <CuiTimeAgo
                    date={moment(getScheduledSnapshotTime({ resource })!)}
                    shouldCapitalize={false}
                  />
                ),
              }}
            />
          ),
        })
      } else {
        problems.push({
          kind: `elasticsearch`,
          id: `es-no-snapshots-at-all`,
          level: `info`,
          iconType: `clock`,
          message: (
            <FormattedMessage
              id='deployment-health-problems.es-no-initial-snapshot'
              defaultMessage='No initial snapshot yet'
            />
          ),
        })
      }
    } else {
      problems.push({
        kind: `elasticsearch`,
        id: `es-snapshots-out-of-date`,
        level: `warning`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-snapshots-out-of-date'
            defaultMessage='Snapshots out of date'
          />
        ),
      })
    }
  } else if (!hasLatestSnapshotSuccess({ resource })) {
    if (latestSuccess) {
      problems.push({
        kind: `elasticsearch`,
        id: `es-failed-with-last-success`,
        level: `warning`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-latest-snapshot-failed-with-last-success'
            defaultMessage='Last successful snapshot was {lastSuccess}'
            values={{
              lastSuccess: <CuiTimeAgo date={moment(latestSuccess)} shouldCapitalize={false} />,
            }}
          />
        ),
      })
    } else {
      problems.push({
        kind: `elasticsearch`,
        id: `es-latest-snapshot-failed`,
        level: `warning`,
        message: (
          <FormattedMessage
            id='deployment-health-problems.es-latest-snapshot-failed'
            defaultMessage='Latest snapshot attempt failed'
          />
        ),
      })
    }
  } else {
    problems.push({
      kind: `elasticsearch`,
      id: `es-snapshot-unhealthy`,
      level: `warning`,
      message: (
        <FormattedMessage
          id='deployment-health-problems.es-snapshots-are-unhealthy'
          defaultMessage='Snapshots are unhealthy'
        />
      ),
    })
  }

  return problems
}

export function getDeploymentEuiHealthColor({
  deployment,
  health,
  deploymentHealthRequest,
}: {
  deployment?: StackDeployment
  health?: DeploymentHealth | null
  deploymentHealthRequest?: AsyncRequestState
}): EuiHealthColor {
  if (!deployment) {
    const problems = []
    return getEuiHealthColor(problems)
  }

  const [problems] = getDeploymentHealthProblems({
    deployment,
    eolStatus: null,
    health,
    deploymentHealthRequest,
  })

  if (find(problems, { id: `deployment-hidden` })) {
    return `subdued`
  }

  if (find(problems, { id: `deployment-terminated` })) {
    return `subdued`
  }

  return getEuiHealthColor(problems ?? [])
}

function getLatestPlanFailureMessage({
  deployment,
  resourceType,
  resource,
}: {
  deployment: StackDeployment
  resourceType: SliderInstanceType
  resource: AnyResourceInfo
}): ReactNode {
  const planAttempt = getLastPlanAttempt({ deployment, sliderInstanceType: resourceType })

  if (!planAttempt) {
    return null
  }

  const parsedError = parseConfigurationChangeError({
    resourceType,
    resource,
    planAttempt,
    linkify: false,
  })

  if (!parsedError) {
    return null
  }

  const { description, title } = parsedError

  return description || title
}

export { parseConfigurationChangeError }
