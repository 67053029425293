/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import type { SaasUserProfile, SaasUserRules } from '@modules/cloud-api/v1/types'
import type { AccountUI, AsyncRequestState, UserProfile } from '@modules/ui-types'

import {
  fetchAccountDetailsRequest,
  getAccountDetails,
  getAccountUI,
  updateAccountDetailsRequest,
  updateAccountPasswordRequest,
} from '@/apps/userconsole/reducers/account'

import {
  fetchAccountDetailsIfNeeded,
  resetUpdateAccountDetailsRequest,
  resetUpdateAccountPasswordRequest,
  updateAccountDetails,
  updateAccountPassword,
} from '../../../../apps/userconsole/actions/account'

import UserAccountSettings from './UserAccountSettings'

import type { ThunkDispatch } from '@/types/redux'
import type { RouteComponentProps } from 'react-router'

interface StateProps {
  accountDetails: UserProfile | undefined
  ui: AccountUI
  fetchAccountDetailsRequest: AsyncRequestState
  updateAccountDetailsRequest: AsyncRequestState
  updateAccountPasswordRequest: AsyncRequestState
}

interface DispatchProps {
  fetchAccountDetailsIfNeeded: () => Promise<any>
  updateAccountDetails: (accountDetails: SaasUserProfile & SaasUserRules) => void
  updateAccountPassword: (data: any) => void
  resetUpdateAccountDetailsRequest: () => void
  resetUpdateAccountPasswordRequest: () => void
}

type ConsumerProps = RouteComponentProps

const mapStateToProps = (state): StateProps => ({
  accountDetails: getAccountDetails(state),
  ui: getAccountUI(state),
  fetchAccountDetailsRequest: fetchAccountDetailsRequest(state),
  updateAccountDetailsRequest: updateAccountDetailsRequest(state),
  updateAccountPasswordRequest: updateAccountPasswordRequest(state),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchAccountDetailsIfNeeded: () => dispatch(fetchAccountDetailsIfNeeded()),
  resetUpdateAccountDetailsRequest: () => dispatch(resetUpdateAccountDetailsRequest()),
  resetUpdateAccountPasswordRequest: () => dispatch(resetUpdateAccountPasswordRequest()),
  updateAccountDetails: (accountDetails) => dispatch(updateAccountDetails(accountDetails)),
  updateAccountPassword: (data) => dispatch(updateAccountPassword(data)),
})

export default withRouter(
  connect<StateProps, DispatchProps, ConsumerProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(UserAccountSettings),
)
