/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { sortBy } from 'lodash'

import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { deviceTypesDisplayOrder } from '@modules/mfa-enforcement/lib'

import type { DeviceType } from './types'

export function useRecommendedMfaDeviceTypes(): DeviceType[] {
  const [_, flags] = useFlagsWhenLoaded()
  const deviceTypes: DeviceType[] = ['GOOGLE']

  if (flags.webAuthn) {
    deviceTypes.push('WEBAUTHN')
  }

  deviceTypes.push('EMAIL')

  return sortBy(deviceTypes, deviceTypesDisplayOrder)
}
