/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import type { SolutionType } from '@modules/solution-selector/types'

import type { MessageDescriptor } from 'react-intl'

export function toDefaultSolutionView(label: SolutionType): DefaultSolutionView {
  switch (label) {
    case `elasticsearch`:
      return `search`
    case `observability`:
      return `observability`
    case `security`:
      return `security`
    default:
      throw new Error(`Unknown solution type: ${label}`) // should never happen
  }
}

export function toSolutionType(view: DefaultSolutionView): SolutionType {
  switch (view) {
    case `search`:
      return `elasticsearch`
    case `observability`:
      return `observability`
    case `security`:
      return `security`
    default:
      throw new Error(`Unknown default solution view: ${view}`) // should never happen
  }
}

const prettySolutionTypes = defineMessages<DefaultSolutionView>({
  search: {
    id: 'projects.pretty.type.elasticsearch',
    defaultMessage: 'Elasticsearch',
  },
  observability: {
    id: 'projects.pretty.type.observability',
    defaultMessage: 'Observability',
  },
  security: {
    id: 'projects.pretty.type.security',
    defaultMessage: 'Security',
  },
})

export function getPrettySolutionType(solutionType: DefaultSolutionView): MessageDescriptor {
  return prettySolutionTypes[solutionType]
}
