/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type { ClusterInstanceInfo } from '@modules/cloud-api/v1/types'

import NodeInstanceStatsDisplay from '../NodeInstanceStatsDisplay'
import Percentage from '../../../../lib/Percentage'
import prettySize from '../../../../lib/prettySize'
import { getUsagePercent, getDiskAllocationRaw } from '../helpers'

import type { FunctionComponent, ReactChild } from 'react'

interface Props {
  instance: ClusterInstanceInfo
  tableView?: boolean
}

const NodeInstanceDiskAllocation: FunctionComponent<Props> = ({ instance, tableView = false }) => {
  const diskAllocation = getDiskAllocation(instance)

  if (tableView) {
    const color = diskAllocation.stressed ? `danger` : `default`
    return (
      <EuiText size='s' color={color} data-test-id='instance-stats-disk-allocation'>
        <Percentage
          value={
            typeof diskAllocation.usagePercent === 'undefined'
              ? getUsagePercent(diskAllocation.used, diskAllocation.available) / 100
              : diskAllocation.usagePercent / 100
          }
        />
      </EuiText>
    )
  }

  return (
    <NodeInstanceStatsDisplay
      {...diskAllocation}
      title={
        <FormattedMessage id='node-instance-disk-allocation' defaultMessage='Disk allocation' />
      }
    />
  )
}

const getDiskAllocation = (instance: ClusterInstanceInfo) => {
  const { diskSpaceAvailable, diskSpaceUsed, usagePercent } = getDiskAllocationRaw(instance)

  const diskSpaceUsedDisplay = diskSpaceUsed ? prettySize(diskSpaceUsed) : `0 GB`

  const stressed = isAlmostFull(usagePercent)

  const diskAllocation: {
    status: ReactChild
    used: number
    available: number
    stressed: boolean
    usagePercent: number
  } = {
    status: '',
    used: diskSpaceUsed,
    available: 0,
    stressed,
    usagePercent: Math.round(usagePercent),
  }

  diskAllocation.available = diskSpaceAvailable
  diskAllocation.status = (
    <EuiFlexGroup alignItems='center' gutterSize='xs' responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiText
          color={stressed ? 'danger' : 'default'}
          size='s'
          data-test-id='node-instance-disk-allocation-used'
          css={css({
            whiteSpace: 'nowrap',
          })}
        >
          {diskSpaceUsedDisplay}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>/</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText
          size='s'
          data-test-id='node-instance-disk-allocation-available'
          css={css({
            whiteSpace: 'nowrap',
          })}
        >
          {diskSpaceAvailable ? prettySize(diskSpaceAvailable) : `0 GB`}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  return diskAllocation
}

const isAlmostFull = (usedPercentage: number) => usedPercentage >= 75

export default NodeInstanceDiskAllocation
