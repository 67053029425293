/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx } from '@emotion/react'

import { EuiFlexGroup, EuiText, EuiTitle, useEuiTheme } from '@elastic/eui'

import type { FC, ReactNode } from 'react'

type TitleAndDescriptionProps = {
  title: ReactNode
  description: ReactNode
}

const TitleAndDescription: FC<TitleAndDescriptionProps> = ({ title, description }) => {
  const {
    euiTheme: {
      colors: { darkShade },
    },
  } = useEuiTheme()

  return (
    <EuiFlexGroup alignItems='center' direction='column' gutterSize='s'>
      <EuiTitle size='m' data-test-id='deployment-title'>
        <h2>{title}</h2>
      </EuiTitle>
      <EuiText
        css={{
          color: darkShade,
        }}
        textAlign='center'
      >
        {description}
      </EuiText>
    </EuiFlexGroup>
  )
}

export default TitleAndDescription
