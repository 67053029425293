/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { _EuiThemeColors } from '@elastic/eui'
import { useEuiTheme } from '@elastic/eui'

import type { SliderInstanceType, SliderNodeType } from '@modules/ui-types'

export const useSliderColorPicker = () => {
  const { euiTheme } = useEuiTheme()

  return (sliderInstanceType: SliderInstanceType): string => {
    switch (sliderInstanceType) {
      case `elasticsearch`:
        return euiTheme.colors.primary
      case `kibana`:
        return euiTheme.colors.success
      case `apm`:
        return euiTheme.colors.accent
      case `appsearch`:
        return euiTheme.colors.warning
      case `enterprise_search`:
        return euiTheme.colors.warning
      default:
        return euiTheme.colors.mediumShade
    }
  }
}

/* This function is only meant for when we explicitly need to discriminate
 * between slider node types, such as in the Deployment Architecture Viz */
export const useSliderInstanceColorPicker = () => {
  const { euiTheme } = useEuiTheme()

  return (sliderInstanceType: SliderInstanceType | SliderNodeType): string => {
    switch (sliderInstanceType) {
      // Sliders
      case `hot`:
        return euiTheme.colors.vis.euiColorVis9
      case `warm`:
        return euiTheme.colors.vis.euiColorVis5
      case `cold`:
        return euiTheme.colors.vis.euiColorVis1
      case `frozen`:
        return euiTheme.colors.lightShade
      case `elasticsearch`:
        return euiTheme.colors.vis.euiColorVis0
      case `kibana`:
        return euiTheme.colors.vis.euiColorVis2
      case `apm`:
      case `integrations_server`:
        return euiTheme.colors.vis.euiColorVis3
      case `appsearch`:
        return euiTheme.colors.vis.euiColorVis0
      case `enterprise_search`:
        return euiTheme.colors.vis.euiColorVis0

      // ES Node types
      case `tiebreaker`:
        return euiTheme.colors.vis.euiColorVis6
      case `master`:
        return euiTheme.colors.darkShade
      case `ml`:
        return euiTheme.colors.vis.euiColorVis8
      case `ingest`:
        return euiTheme.colors.vis.euiColorVis4
      default:
        return euiTheme.colors.mediumShade
    }
  }
}
