/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import type {
  DeploymentGetResponse,
  DeploymentTemplateInfoV2,
  DeploymentHealth,
} from '@modules/cloud-api/v1/types'
import type { ElasticsearchCluster, ProfileState, AsyncRequestState } from '@modules/ui-types'
import type { EolStatusResponse } from '@modules/ui-types/eolStatus'
import { withSmallErrorBoundary } from '@modules/cui/Boundary'
import Feature from '@modules/utils/feature'
import withPermissions from '@modules/permissions-components/PermissionsGate/withPermissions'

import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { getDeploymentHealth } from '@/reducers/deploymentHealth'
import { getEolStatus } from '@/reducers/eolStatus'
import { getStackDeployment } from '@/reducers/stackDeployments'
import { getDeploymentTemplate } from '@/reducers/deploymentTemplates'
import { getCluster } from '@/reducers/clusters'
import { getProfile } from '@/apps/userconsole/reducers/profile'
import { fetchDeploymentHealthRequest } from '@/reducers/asyncRequests/registry'

import { fetchDeploymentTemplates } from '../../../../actions/topology/deploymentTemplates'
import { getConfigForKey, isFeatureActivated } from '../../../../selectors'
import { withStackDeploymentRouteParams } from '../../../../components/StackDeploymentEditor'
import { getHerokuCluster } from '../../../../lib/heroku'

import UserconsoleChromeNavigation from './UserconsoleChromeNavigation'

import type { WithStackDeploymentRouteParamsProps } from '../../../../components/StackDeploymentEditor'
import type { RouteConfig } from 'react-router-config'

type StateProps = {
  deployment?: ElasticsearchCluster | null
  deploymentTemplate?: DeploymentTemplateInfoV2
  stackDeployment?: DeploymentGetResponse | null
  defaultRegionId?: string
  regionId?: string
  isHeroku: boolean
  isPortalFeatureEnabled: boolean
  showHelpPage: boolean
  showDeploymentFeaturesPage: boolean
  serverlessFeature: boolean
  profile: ProfileState
  eolStatus: EolStatusResponse | null
  deploymentHealthRequest?: AsyncRequestState
  health?: DeploymentHealth | null
}

type DispatchProps = {
  fetchDeploymentTemplates: (params: {
    regionId: string
    stackVersion: string
    showMaxZones: boolean
  }) => void
}

type OwnProps = {
  routes: RouteConfig[]
}

type ConsumerProps = WithStackDeploymentRouteParamsProps & OwnProps

const mapStateToProps = (
  state,
  { regionId: receivedRegionId, deploymentId, stackDeploymentId }: ConsumerProps,
): StateProps => {
  const isHeroku = getConfigForKey(state, `APP_FAMILY`) === `heroku`
  const herokuCluster = getHerokuCluster()

  const deployment =
    isHeroku && herokuCluster
      ? getCluster(state, herokuCluster.regionId, herokuCluster.id)
      : getCluster(state, receivedRegionId, deploymentId!)

  const regionId = isHeroku && herokuCluster ? herokuCluster.regionId : receivedRegionId

  const stackDeployment = getStackDeployment(state, stackDeploymentId)

  const deploymentTemplate = resolveDeploymentTemplate()

  const profile = getProfile(state)
  const organizationId = profile?.organization_id

  return {
    regionId,
    deployment,
    deploymentTemplate,
    defaultRegionId: getConfigForKey(state, `DEFAULT_REGION`),
    isHeroku,
    profile,
    stackDeployment,
    isPortalFeatureEnabled: isFeatureActivated(state, Feature.cloudPortalEnabled),
    serverlessFeature: isFeatureActivated(state, Feature.serverless),
    showHelpPage: isFeatureActivated(state, Feature.showHelpPage),
    showDeploymentFeaturesPage: organizationId !== undefined,
    eolStatus: getEolStatus(state),
    health: getDeploymentHealth(state, stackDeploymentId!),
    deploymentHealthRequest: fetchDeploymentHealthRequest(state, stackDeploymentId!),
  }

  function resolveDeploymentTemplate() {
    // navigation inside a deployment, so use the template ID referenced in the deployment
    if (stackDeployment) {
      const deploymentTemplateId = getDeploymentTemplateId({ deployment: stackDeployment })
      const version = getVersion({ deployment: stackDeployment })

      return getDeploymentTemplate(state, regionId!, deploymentTemplateId!, version)
    }

    if (deployment && deployment.plan) {
      return getDeploymentTemplate(
        state,
        regionId,
        deployment.deploymentTemplateId!,
        deployment.plan.version,
      )
    }

    return
  }
}

const mapDispatchToProps: DispatchProps = {
  fetchDeploymentTemplates,
}

export default withSmallErrorBoundary(
  withStackDeploymentRouteParams<OwnProps>(
    connect<StateProps, DispatchProps, ConsumerProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(
      withPermissions({
        hasListApiKeysPermission: {
          permissionsToCheck: [{ type: 'api-key', action: 'list' }],
        },
      })(UserconsoleChromeNavigation),
    ),
  ),
)
