/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useIntl } from 'react-intl'

import type { GettingStartedPhase } from './types'

export const useDeploymentGettingStartedTitleAndDescription = () => {
  const { formatMessage } = useIntl()

  return (gettingStartedPhase: GettingStartedPhase) => {
    let title = formatMessage({
      id: 'deployment-waiting-experience.title.save-creds',
      defaultMessage: 'Save the deployment credentials',
    })

    let description = formatMessage({
      id: 'deployment-waiting-experience.sub-title',
      defaultMessage:
        'These root credentials are shown only once. They provide super user access to your deployment. Keep them safe.',
    })

    if (
      gettingStartedPhase === 'creating-deployment' ||
      gettingStartedPhase === 'deployment-created'
    ) {
      title = formatMessage({
        id: 'deployment-waiting-experience.title.creating-deployment',
        defaultMessage: 'Discover what you can do with Elastic',
      })

      description = formatMessage({
        id: 'deployment-waiting-experience.description.creating-deployment',
        defaultMessage: 'We have a video prepared for you to watch while you are waiting',
      })
    }

    return { title, description }
  }
}
