/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiCallOut } from '@elastic/eui'

import { useConfig } from '@modules/cui/ConfigContext'
import { hasDefaultSolutionViewFeature } from '@modules/deployment-lib'
import Feature from '@modules/utils/feature'

import type { FC } from 'react'

const SolutionViewWarningCallout: FC<{ version: string | null }> = ({ version }) => {
  const shouldShowSolutionViewWarning = useShowSolutionViewWarning(version)

  if (!shouldShowSolutionViewWarning) {
    return null
  }

  return (
    <EuiCallOut
      iconType='iInCircle'
      color='warning'
      title={
        <FormattedMessage defaultMessage='Warning' id='create-deployment-from-template.warning' />
      }
    >
      <FormattedMessage
        id='create-deployment-from-template.solution-views-not-available'
        defaultMessage={`<underscore>Solution views</underscore> aren't available for the selected version. The navigation will show all Elastic Stack and solution features.`}
        values={{
          underscore: (msg) => <span css={{ textDecoration: 'underline' }}>{msg}</span>,
        }}
      />
    </EuiCallOut>
  )
}

function useShowSolutionViewWarning(version: string | null) {
  const isDefaultSolutionViewEnabled = useConfig(Feature.showDefaultSolutionView)

  if (version === null) {
    return false
  }

  if (!isDefaultSolutionViewEnabled) {
    return false
  }

  if (hasDefaultSolutionViewFeature(version)) {
    return false
  }

  return true
}

export default SolutionViewWarningCallout
