/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import moment from 'moment'
import { round } from 'lodash'
import { useIntl } from 'react-intl'

import {
  Axis,
  BarSeries,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  Position,
  ScaleType,
  Settings,
} from '@elastic/charts'
import type { WithEuiThemeProps } from '@elastic/eui'
import { EuiLoadingChart, withEuiTheme } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'
import { colorForProduct } from '@modules/billing-lib/colorGenerator'
import type { ChartItemType } from '@modules/billing-api/customTypes'

import EmptyDataPrompt from '@/components/User/BillingUsage/BillingUsageOverviewV2/EmptyDataPrompt'
import { IconType } from '@/components/User/BillingUsage/BillingUsageOverviewV2/types'

import { ChartType, ViewBy } from '../../types'
import ChartTooltip from '../ChartTooltip'
import useFilters from '../Filters/useFilters'

import { prepareAggregatedProductDataAccessors, getSeriesName } from './utils'

import type { Props } from './types'
import type { FunctionComponent } from 'react'

const chartComponent = {
  [ChartType.BAR]: BarSeries,
  [ChartType.LINE]: LineSeries,
}

const viewByFormat = {
  [ViewBy.DAY]: 'MMM DD',
  [ViewBy.MONTH]: 'MMM YYYY',
}

const chartTheme = {
  LIGHT: LIGHT_THEME,
  DARK: DARK_THEME,
}

const stackAccessors = {
  [ChartType.BAR]: ['timestamp'],
  [ChartType.LINE]: [],
}

const ProductChart: FunctionComponent<Props & WithEuiThemeProps> = ({
  data = [],
  isLoading = false,
  theme,
}) => {
  const { filters } = useFilters()

  // nosemgrep
  const ChartComponent = chartComponent[filters.chartType]
  const { formatMessage } = useIntl()

  if (isLoading) {
    return <EuiLoadingChart size='xl' />
  }

  // Because the x-axis is ordinal, we need to define the domain.
  const xDomain = data.map(({ timestamp }) => timestamp)
  const yAccessors = prepareAggregatedProductDataAccessors(filters)

  return yAccessors.length === 0 ? (
    <EmptyDataPrompt
      iconType={filters.chartType === ChartType.BAR ? IconType.BAR_CHART : IconType.LINE_CHART}
    />
  ) : (
    <Chart size={['100%', '100%']}>
      <Settings baseTheme={chartTheme[theme.colorMode]} xDomain={xDomain} />
      <ChartTooltip />
      <Axis
        id='amount'
        position={Position.Left}
        gridLine={{ visible: true }}
        ticks={6}
        tickFormat={(d) => String(round(Math.abs(d), 4).toFixed(4))} // the same format as in the table below the graph
        title='ECU'
      />
      <Axis
        id='timestamp'
        position={Position.Bottom}
        tickFormat={(d) => moment.unix(d).utc().format(viewByFormat[filters.viewBy])}
        showOverlappingTicks={false}
        showOverlappingLabels={false}
        ticks={4}
      />
      <ChartComponent
        id='usage'
        name={({ yAccessor }) => getSeriesName(yAccessor as ChartItemType, formatMessage)}
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor='timestamp'
        yAccessors={yAccessors}
        stackAccessors={stackAccessors[filters.chartType]}
        splitSeriesAccessors={[]}
        data={data}
        color={({ yAccessor }) => colorForProduct.get(yAccessor as ChartItemType)}
      />
    </Chart>
  )
}

export default withEuiTheme(withErrorBoundary(ProductChart))
