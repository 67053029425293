/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'

import { EuiSpacer } from '@elastic/eui'

import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import { insertSolutionType } from '@modules/deployment-creation-wizard/lib'

import CreateStackDeploymentEditor from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor'

import CreateStackDeploymentEditorDependencies from '../CreateStackDeploymentEditorDependencies'

import SolutionViewHeader from './SolutionViewHeader'

import type { CreateEditorComponentConsumerProps } from '../types'
import type { FC } from 'react'

interface SolutionTypeAwareCreateStackDeploymentEditorWrapperProps {
  solutionView: DefaultSolutionView
}

const SolutionTypeAwareCreateStackDeploymentEditorWrapper: FC<
  SolutionTypeAwareCreateStackDeploymentEditorWrapperProps
> = ({ solutionView }) => (
  <CreateStackDeploymentEditorDependencies>
    {(props: CreateEditorComponentConsumerProps) => (
      <CreateStackDeploymentEditor
        {...props}
        editorState={insertSolutionType(props.editorState, solutionView)}
        deploymentTitle={
          <Fragment>
            <SolutionViewHeader solutionView={solutionView} />
            <EuiSpacer size='l' />
            <div
              css={{
                width: '100vh',
              }}
            />
          </Fragment>
        }
      />
    )}
  </CreateStackDeploymentEditorDependencies>
)

export default SolutionTypeAwareCreateStackDeploymentEditorWrapper
