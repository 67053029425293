/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React, { Fragment } from 'react'

import { EPP4_RELEASE_DATE } from '@modules/billing-lib/constants'

import ExternalLink from '../../../ExternalLink'

interface GetBillingPdfs {
  usage_pdf_download?: string
  invoice_pdf_download?: string
  external_invoice_pdf_download?: string
  number: string | number
  issue_date: string
}

export function getBillingPdfs({
  usage_pdf_download,
  invoice_pdf_download,
  external_invoice_pdf_download,
  number,
  issue_date,
}: GetBillingPdfs): JSX.Element {
  if (!usage_pdf_download && !invoice_pdf_download && !external_invoice_pdf_download) {
    return <span>{number}</span>
  }

  const billing_pdfs: JSX.Element[] = []

  if (usage_pdf_download) {
    billing_pdfs.push(
      <ExternalLink
        style={{ display: 'block' }}
        data-test-id='usage-pdf-link'
        href={usage_pdf_download}
        key={usage_pdf_download}
      >
        {number}
      </ExternalLink>,
    )
  }

  if (invoice_pdf_download) {
    billing_pdfs.push(
      <ExternalLink
        style={{ display: 'block' }}
        data-test-id='invoice-pdf-link'
        href={invoice_pdf_download}
        key={invoice_pdf_download}
      >
        {issue_date >= EPP4_RELEASE_DATE ? (
          <FormattedMessage
            id='billing-statements.statement-pdf'
            defaultMessage='Statement_{number}'
            values={{ number }}
          />
        ) : (
          <FormattedMessage
            id='billing-statements.receipt-pdf'
            defaultMessage='Receipt_{number}'
            values={{ number }}
          />
        )}
      </ExternalLink>,
    )
  }

  if (external_invoice_pdf_download) {
    billing_pdfs.push(
      <ExternalLink
        style={{ display: 'block' }}
        data-test-id='external-pdf-link'
        href={external_invoice_pdf_download}
        key={external_invoice_pdf_download}
      >
        <FormattedMessage
          id='billing-statements.invoice-pdf'
          defaultMessage='Invoice_{number}'
          values={{ number }}
        />
      </ExternalLink>,
    )
  }

  return <Fragment>{billing_pdfs}</Fragment>
}
