/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { noop } from 'lodash'

import { defaultFilters } from './filtersReducer'

import type { Dispatch } from 'react'
import type { ReducerState, Actions } from './filtersReducer'

export interface FiltersContextProps {
  filters: ReducerState
  dispatch: Dispatch<Actions>
}

export const FiltersContext = React.createContext<FiltersContextProps>({
  filters: defaultFilters,
  dispatch: noop,
})
