/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { noop } from 'lodash'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import type { EuiStepsHorizontalProps, EuiStepStatus } from '@elastic/eui'

import type { MessageDescriptor } from 'react-intl'
import type { CreateDeploymentWizardStep } from './types'

const createDeploymentMessages = defineMessages<CreateDeploymentWizardStep>({
  'solution-selector': {
    id: 'deployment-getting-started.create-deployment',
    defaultMessage: 'Choose solution view',
  },
  'deployment-editor': {
    id: 'deployment-getting-started.review-settings',
    defaultMessage: 'Review settings',
  },
  'getting-started': {
    id: 'deployment-getting-started.launch-deployment',
    defaultMessage: 'Launch deployment',
  },
})

function getStepStatus(
  currentStep: CreateDeploymentWizardStep,
  stepId: CreateDeploymentWizardStep,
): EuiStepStatus {
  if (currentStep === stepId) {
    return `current`
  }

  switch (currentStep) {
    case `solution-selector`:
      return `disabled`
    case `deployment-editor`:
      return stepId === `solution-selector` ? `complete` : `disabled`
    case `getting-started`:
      return 'complete'
    default:
      throw new Error(`Unknown step: ${currentStep}`) // should never happen
  }
}

const createDeploymentMessagesEntries = Object.entries(createDeploymentMessages) as Array<
  [CreateDeploymentWizardStep, MessageDescriptor]
>

const useDeploymentCreationWizardSteps: (
  initialStep: CreateDeploymentWizardStep,
) => [
  CreateDeploymentWizardStep,
  React.Dispatch<React.SetStateAction<CreateDeploymentWizardStep>>,
  (
    currentStep: CreateDeploymentWizardStep,
    lastStepCompleted?: boolean,
  ) => EuiStepsHorizontalProps['steps'],
] = (initialStep) => {
  const { formatMessage } = useIntl()

  const [currentStep, setCurrentStep] = useState<CreateDeploymentWizardStep>(initialStep)

  function generateStepDetails(
    currentStepId: CreateDeploymentWizardStep,
    lastStepCompleted?: boolean,
  ): EuiStepsHorizontalProps['steps'] {
    return createDeploymentMessagesEntries.map(([stepId, messageDescriptor]) => ({
      title: formatMessage(messageDescriptor),
      onClick:
        currentStep === 'deployment-editor' && stepId === 'solution-selector'
          ? () => setCurrentStep(stepId)
          : noop,
      status: lastStepCompleted === true ? 'complete' : getStepStatus(currentStepId, stepId),
    }))
  }

  return [currentStep, setCurrentStep, generateStepDetails]
}

export default useDeploymentCreationWizardSteps
