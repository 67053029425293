/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import type { InstanceSummary, StackDeployment } from '@modules/ui-types'
import type { ElasticsearchResourceInfo } from '@modules/cloud-api/v1/types'
import type { CuiTableColumn } from '@modules/cui/Table'

import { CuiSliderLogo } from '@/components/SliderLogo'
import { getVersion } from '@/lib/stackDeployments/selectors/fundamentals'
import { isNodePausedByUser } from '@/lib/stackDeployments/instanceStatus'

import { createInstanceDisplayName } from '../../../reducers/clusters/createCluster'

import NodeInstanceDiskAllocation from './NodeInstanceDiskAllocation'
import FrozenNodeUsage from './FrozenNodeUsage'
import NodeInstanceJVMMemoryPressure from './NodeInstanceJVMMemoryPressure'
import NodeTileMenu from './NodeTileMenu'
import NodeInstanceStatus from './NodeInstanceStatus'
import NodeInstanceNativeMemoryPressure from './NodeInstanceNativeMemoryPressure'
import { getPrimarySize } from './NodeInstanceVisualization/NodeInstanceVisualization'
import {
  getNodeRoles,
  getJVMMemoryPressure,
  getDiskAllocationRaw,
  getInstanceHealth,
  getSize,
} from './helpers'

function InstancesTable({
  zoneIds,
  zoneSummaries,
  deployment,
  showNativeMemoryPressure = false,
}: {
  zoneIds: string[]
  zoneSummaries: {
    [key: string]: InstanceSummary[]
  }
  deployment: StackDeployment
  showNativeMemoryPressure?: boolean
}) {
  const { rows, anyFrozenNodes } = getRows(zoneIds, zoneSummaries)
  const columns = getColumns(deployment, showNativeMemoryPressure, anyFrozenNodes)

  return <CuiTable rows={rows} columns={columns} />
}

export function getRows(zoneIds, zoneSummaries) {
  const rows: InstanceSummary[] = []

  zoneIds.map((zoneId) =>
    zoneSummaries[zoneId].flatMap((summary) => {
      rows.push(summary)
    }),
  )

  const anyFrozenNodes = rows.some((instance) =>
    instance.instance.node_roles?.includes(`data_frozen`),
  )

  return { rows, anyFrozenNodes }
}

function getColumns(deployment, showNativeMemoryPressure, anyFrozenNodes) {
  const nameColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage id='stack-deployments-instance-table.name-label' defaultMessage='Name' />
    ),
    render: (instance: InstanceSummary) => {
      const isMlNodeType = instance.instance.service_roles
        ? instance.instance.service_roles.includes(`ml`)
        : false
      const sliderNodeType = isMlNodeType ? 'ml' : undefined

      const version = getVersion({ deployment })

      const isPaused = isNodePausedByUser(instance.instance)

      return (
        <EuiFlexGroup
          alignItems='center'
          css={css(isPaused && { opacity: 0.7 })}
          justifyContent='center'
          gutterSize='s'
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <CuiSliderLogo
              sliderInstanceType={instance.kind}
              sliderNodeType={sliderNodeType}
              version={version}
              size='m'
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h3 data-test-id='node-tile-title'>
                {createInstanceDisplayName(instance.instance.instance_name)}
              </h3>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    width: `11%`,
  }

  const healthColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.health-label'
        defaultMessage='Health'
      />
    ),
    render: (instance: InstanceSummary) => (
      <NodeInstanceStatus deployment={deployment} instanceSummary={instance} />
    ),
    width: `6%`,
    sortKey: (instance: InstanceSummary) => getInstanceHealth(instance.instance),
  }

  const sizeColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage id='stack-deployments-instance-table.size-label' defaultMessage='Size' />
    ),
    render: (instance: InstanceSummary) => getPrimarySize(instance.instance),
    width: `7%`,
    sortKey: (instance: InstanceSummary) => getSize(instance.instance)?.size,
  }

  const icColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage id='stack-deployments-instance-table.ic-label' defaultMessage='Type' />
    ),
    render: (instance: InstanceSummary) => instance.instance.instance_configuration?.name,
    width: `15%`,
    sortKey: (instance: InstanceSummary) => instance.instance.instance_configuration?.name,
  }

  const nodeRolesColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.node-roles-label'
        defaultMessage='Roles'
      />
    ),
    render: (instance: InstanceSummary) => getNodeRoles(instance),
    width: `15%`,
    sortKey: (instance: InstanceSummary) => getNodeRoles(instance),
  }

  const versionColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.version-label'
        defaultMessage='Version'
      />
    ),
    render: (instance: InstanceSummary) => instance.instance.service_version,
    sortKey: (instance: InstanceSummary) => instance.instance.service_version,
    width: `5%`,
  }

  const zoneColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage id='stack-deployments-instance-table.zone-label' defaultMessage='Zone' />
    ),
    render: (instance: InstanceSummary) => instance.instance.zone,
    width: `7%`,
    sortKey: (instance: InstanceSummary) => instance.instance.zone,
  }

  const actionsMenuColumn: CuiTableColumn<InstanceSummary> = {
    render: (instance: InstanceSummary) => (
      <NodeTileMenu
        deployment={deployment}
        instance={instance.instance}
        instanceConfiguration={instance.instance.instance_configuration}
        resource={instance.resource as ElasticsearchResourceInfo}
        kind={instance.kind}
      />
    ),
    width: `2%`,
  }

  const diskAllocationColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.disk-allocation-label'
        defaultMessage='Disk'
      />
    ),
    render: (instance: InstanceSummary) => {
      const isFrozen = instance.instance.node_roles?.includes(`data_frozen`)
      return !isFrozen ? (
        <NodeInstanceDiskAllocation instance={instance.instance} tableView={true} />
      ) : null
    },
    width: `5%`,
    sortKey: (instance: InstanceSummary) => getDiskAllocationRaw(instance.instance).usagePercent,
  }

  const frozenUsageColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.frozen-usage-label'
        defaultMessage='Frozen Usage'
      />
    ),
    render: (instance: InstanceSummary) => {
      const isFrozen = instance.instance.node_roles?.includes(`data_frozen`)
      return isFrozen ? (
        <FrozenNodeUsage instance={instance.instance} deployment={deployment} />
      ) : null
    },
    width: `10%`,
  }

  const jvmMemoryPressureColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.jvm-mem-label'
        defaultMessage='JVM Memory'
      />
    ),
    render: (instance: InstanceSummary) => {
      const isMlNodeType = instance.instance.service_roles
        ? instance.instance.service_roles.includes(`ml`)
        : false

      return !isMlNodeType || (isMlNodeType && showNativeMemoryPressure) ? (
        <NodeInstanceJVMMemoryPressure instance={instance.instance} tableView={true} />
      ) : null
    },
    sortKey: (instance: InstanceSummary) => getJVMMemoryPressure(instance.instance),
    width: `8%`,
  }

  const nativeMemoryPressureColumn: CuiTableColumn<InstanceSummary> = {
    label: (
      <FormattedMessage
        id='stack-deployments-instance-table.native-mem-label'
        defaultMessage='Native Memory'
      />
    ),
    render: (instance: InstanceSummary) => {
      const isKibana = instance.kind === `kibana`

      return showNativeMemoryPressure || isKibana ? (
        <NodeInstanceNativeMemoryPressure instance={instance.instance} tableView={true} />
      ) : null
    },
    width: `9%`,
    sortKey: (instance: InstanceSummary) => instance.instance.memory?.native_memory_pressure,
  }

  const columns: Array<CuiTableColumn<InstanceSummary>> = [
    nameColumn,
    healthColumn,
    sizeColumn,
    icColumn,
    nodeRolesColumn,
    versionColumn,
    zoneColumn,
    diskAllocationColumn,
    jvmMemoryPressureColumn,
    nativeMemoryPressureColumn,
  ]

  if (anyFrozenNodes) {
    columns.push(frozenUsageColumn)
  }

  columns.push(actionsMenuColumn)

  return columns
}

export default InstancesTable
