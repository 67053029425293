/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { useSolutionOptions } from '@modules/solution-selector/hooks'
import SelectSolutionCard from '@modules/solution-selector/SelectSolutionCard'
import type { SolutionType } from '@modules/solution-selector/types'

import type { FC } from 'react'

type DefaultSolutionViewSelectorProps = {
  onChooseSolution: (label: SolutionType) => void
}

const DefaultSolutionViewSelector: FC<DefaultSolutionViewSelectorProps> = ({
  onChooseSolution,
}) => {
  const solutionOptions = useSolutionOptions()

  return (
    <EuiFlexGroup justifyContent='center'>
      {solutionOptions.map((solutionOption) => (
        <EuiFlexItem key={solutionOption.label}>
          <SelectSolutionCard {...solutionOption} onNext={onChooseSolution} />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}

export default DefaultSolutionViewSelector
