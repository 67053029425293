/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { PureComponent, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiContextMenu,
  EuiHorizontalRule,
  EuiPopover,
  EuiSpacer,
  EuiToolTip,
} from '@elastic/eui'

import type {
  ClusterInstanceConfigurationInfo,
  ClusterInstanceInfo,
  ElasticsearchResourceInfo,
} from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, SliderInstanceType, StackDeployment } from '@modules/ui-types'
import { addToast } from '@modules/cui/Toasts'
import { CuiButtonEmpty } from '@modules/cui/Button'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { isSystemOwned } from '@/lib/stackDeployments/selectors/metadata'
import {
  isNodePausedByUser,
  hasStoppedRoutingRequests,
} from '@/lib/stackDeployments/instanceStatus'
import { getSliderPrettyName } from '@/lib/sliders/messages'

import StopRoutingRequestsModal from '../StopRoutingRequestsModal'
import PauseInstanceModal from '../PauseInstanceModal'
import VacateNodeThroughResourceModal from '../VacateNodeThroughResourceModal'
import VacateNodeThroughAllocatorModal from '../VacateNodeThroughAllocatorModal'
import InstanceCapacityOverrideModal from '../InstanceCapacityOverrideModal'
import DiskQuotaOverrideModal from '../DiskQuotaOverrideModal'
import DeploymentLockGate from '../../../DeploymentLockGate'
import { deploymentEditUrl } from '../../../../lib/urlBuilder'
import {
  startHeapDumpCaptureSuccessToast,
  startHeapDumpCaptureFailToast,
} from '../../../ManageHeapDumps/ManageHeapDumps'

import type { WrappedComponentProps } from 'react-intl'
import type { ReactNode } from 'react'

export interface Props extends WrappedComponentProps {
  canMoveNode: boolean
  captureThreadDump: () => Promise<void>
  captureThreadDumpRequest: AsyncRequestState
  deployment: StackDeployment
  disableNodeControlsIfPlanPending?: boolean
  diskQuotaOverride: boolean
  fetchDeployment: () => void
  instance: ClusterInstanceInfo
  instanceCapacityOverride: boolean
  instanceConfiguration?: ClusterInstanceConfigurationInfo
  isAdminConsole: boolean
  kind: SliderInstanceType
  resource: ElasticsearchResourceInfo
  startHeapDumpCapture: () => Promise<void>
  startHeapDumpCaptureRequest: AsyncRequestState
  startInstance: () => Promise<void>
  startRouting: () => Promise<void>
  stopInstance: () => Promise<void>
  stopRouting: () => Promise<void>
  type: 'node' | 'instance'
}

interface State {
  isPopoverOpen: boolean
  showMoveNodeModal: boolean
  showInstanceCapacityModal: boolean
  showDiskQuotaModal: boolean
  showStopRoutingRequestsModal: boolean
  showPauseInstanceModal: boolean
}

const messages = defineMessages({
  buttonAriaLabel: {
    id: 'node-tile-menu-button-aria-label',
    defaultMessage: 'Instance menu items',
  },
  editSettings: {
    id: 'node-tile-menu-edit-configuration',
    defaultMessage: 'Edit configuration',
  },
  stopRoutingRequests: {
    id: 'node-tile-menu-stop-routing-requests',
    defaultMessage: 'Stop routing requests',
  },
  startRoutingRequests: {
    id: 'node-tile-menu-start-routing-requests',
    defaultMessage: 'Start routing requests',
  },
  pauseInstance: {
    id: 'node-tile-menu-pause-instance',
    defaultMessage: 'Pause instance',
  },
  startInstance: {
    id: 'node-tile-menu-start-instance',
    defaultMessage: 'Resume instance',
  },
  moveInstance: {
    id: 'node-tile-menu-stop-move-instance',
    defaultMessage: 'Move instance (node)',
  },
  overrideInstanceSize: {
    id: 'node-tile-menu-stop-override-instance-size',
    defaultMessage: 'Override instance size',
  },
  overrideDiskQuota: {
    id: 'node-tile-menu-stop-override-disk-quota',
    defaultMessage: 'Override disk quota',
  },
  captureHeapDump: {
    id: 'node-tile-menu-capture-heap-dump',
    defaultMessage: 'Capture heap dump',
  },
  captureThreadDump: {
    id: 'node-tile-menu-capture-thread-dump',
    defaultMessage: 'Capture thread dump',
  },
  disabledWhileConfigurationInProgress: {
    id: 'node-tile-menu.disabled-while-configuring',
    defaultMessage: 'Disabled while configuration in progress.',
  },
})

const itemFullWidthStyle = css({
  width: '100%',

  '.euiButtonEmpty__content': {
    justifyContent: 'flex-start',
  },
})

class NodeTileMenu extends PureComponent<Props, State> {
  private configurationChangeInProgress

  state = {
    isPopoverOpen: false,
    showMoveNodeModal: false,
    showInstanceCapacityModal: false,
    showDiskQuotaModal: false,
    showStopRoutingRequestsModal: false,
    showPauseInstanceModal: false,
  }

  componentDidMount() {
    this.configurationChangeInProgress = this.isConfigurationChangeInProgress()
  }

  componentDidUpdate() {
    this.configurationChangeInProgress = this.isConfigurationChangeInProgress()
  }

  render() {
    const {
      intl: { formatMessage },
      deployment,
      kind,
      instance,
      instanceConfiguration,
      isAdminConsole,
      resource,
      type,
    } = this.props

    const {
      showInstanceCapacityModal,
      showDiskQuotaModal,
      showStopRoutingRequestsModal,
      showPauseInstanceModal,
    } = this.state

    return (
      <DeploymentLockGate>
        <EuiPopover
          button={
            <EuiButtonIcon
              aria-label={formatMessage(messages.buttonAriaLabel)}
              color='text'
              iconType='boxesVertical'
              onClick={this.toggle}
              data-test-id='node-tile-menu-button'
            />
          }
          isOpen={this.state.isPopoverOpen}
          closePopover={this.closePopover}
          panelPaddingSize='none'
        >
          {isAdminConsole
            ? this.renderAdminConsoleMenuContent()
            : this.renderUserConsoleMenuContent()}
        </EuiPopover>

        {this.renderMoveInstanceModal()}

        {showInstanceCapacityModal && (
          <InstanceCapacityOverrideModal
            deployment={deployment}
            instance={instance}
            resourceKind={kind}
            resource={resource}
            instanceConfiguration={instanceConfiguration!}
            close={this.hideInstanceCapacityModal}
          />
        )}

        {showDiskQuotaModal && (
          <DiskQuotaOverrideModal
            deployment={deployment}
            instance={instance}
            resourceKind={kind}
            resource={resource}
            instanceConfiguration={instanceConfiguration}
            close={this.hideDiskQuotaModal}
          />
        )}

        {showStopRoutingRequestsModal && (
          <StopRoutingRequestsModal
            type={type}
            close={this.hideStopRoutingRequestsModal}
            onConfirm={this.onConfirmStopRoutingRequests}
          />
        )}

        {showPauseInstanceModal && (
          <PauseInstanceModal
            type={type}
            close={this.hidePauseInstanceModal}
            onConfirm={this.onConfirmPauseInstance}
          />
        )}
      </DeploymentLockGate>
    )
  }

  renderUserConsoleMenuContent() {
    return (
      <EuiContextMenu
        initialPanelId='root'
        data-test-id='node-tile-menu-root'
        panels={[
          {
            id: 'root',
            content: (
              <Fragment>
                {this.renderStopRoutingRequestsItem()}

                <EuiSpacer size='xs' />

                {this.renderEditConfigurationsItem()}
              </Fragment>
            ),
          },
        ]}
      />
    )
  }

  renderAdminConsoleMenuContent() {
    return (
      <EuiContextMenu
        initialPanelId='root'
        data-test-id='node-tile-menu-root'
        panels={[
          {
            id: 'root',
            content: (
              <Fragment>
                <EuiSpacer size='xs' />

                {this.renderEditConfigurationsItem()}

                <EuiSpacer size='xs' />

                <EuiHorizontalRule margin='none' />

                {this.renderEceInstanceActionItems()}

                <EuiHorizontalRule margin='none' />

                {this.renderEceOverrideActionItems()}
                {this.renderEceHeapDumpActionItems()}
                {this.renderEceThreadDumpActionItem()}
              </Fragment>
            ),
          },
        ]}
      />
    )
  }

  renderMoveInstanceModal() {
    const { fetchDeployment, instance, kind, deployment, resource } = this.props

    const { showMoveNodeModal } = this.state

    if (!showMoveNodeModal) {
      return null
    }

    if (kind === 'elasticsearch') {
      return (
        <VacateNodeThroughResourceModal
          deployment={deployment}
          resource={resource}
          instance={instance}
          close={this.hideMoveNodeModal}
          onAfterVacate={fetchDeployment}
        />
      )
    }

    return (
      <VacateNodeThroughAllocatorModal
        kind={kind}
        resource={resource}
        instance={instance}
        close={this.hideMoveNodeModal}
        onAfterVacate={fetchDeployment}
      />
    )
  }

  renderEditConfigurationsItem(): ReactNode {
    const {
      intl: { formatMessage },
      instance,
      instanceConfiguration,
    } = this.props

    const isTieBreaker = instance.instance_name.includes(`tiebreaker`)
    const hash = getInstanceConfigurationHash()

    const disabled = this.configurationChangeInProgress

    const content = (
      <EuiButtonEmpty
        css={itemFullWidthStyle}
        color='text'
        iconType='pencil'
        data-test-id='node-tile-menu-edit-configuration'
        href={this.getEditPageHref(hash)}
        disabled={disabled}
      >
        {formatMessage(messages.editSettings)}
      </EuiButtonEmpty>
    )

    if (disabled) {
      return (
        <EuiToolTip
          position='right'
          content={formatMessage(messages.disabledWhileConfigurationInProgress)}
        >
          {content}
        </EuiToolTip>
      )
    }

    return content

    function getInstanceConfigurationHash(): string | undefined {
      if (isTieBreaker) {
        return 'tiebreaker'
      }

      if (instanceConfiguration) {
        return instanceConfiguration.id
      }

      return
    }
  }

  renderManageInstanceItems() {
    return (
      <Fragment>
        <EuiSpacer size='xs' />

        {this.renderStopRoutingRequestsItem()}

        <EuiSpacer size='xs' />

        {this.renderPauseInstanceItem()}
      </Fragment>
    )
  }

  renderStopRoutingRequestsItem(): ReactNode {
    const {
      deployment,
      instance,
      intl: { formatMessage },
    } = this.props

    // curently disabled is only based on systemOwned but previously we had other conditions. Stripped those out but leaving the overall framework as I expect
    // at some point we'll need more conditions for disabled
    const systemOwned = isSystemOwned({ deployment })
    const disabled = systemOwned
    const stoppedRoutingRequests = hasStoppedRoutingRequests(instance)

    let toolTipContent

    if (systemOwned) {
      if (stoppedRoutingRequests) {
        toolTipContent = (
          <FormattedMessage
            id='node-tile-menu.cannot-start-routing-system-deployment'
            defaultMessage='You cannot start routing to a system deployment.'
          />
        )
      }

      if (!stoppedRoutingRequests) {
        toolTipContent = (
          <FormattedMessage
            id='node-tile-menu.cannot-stop-routing-system-deployment'
            defaultMessage='You cannot stop routing to a system deployment.'
          />
        )
      }
    }

    const panelContent = (
      <PermissionsGate
        permissions={[
          {
            type: 'deployment',
            action: 'maintenance',
            id: deployment.id,
          },
        ]}
      >
        {({ hasPermissions }) =>
          stoppedRoutingRequests ? (
            <EuiButtonEmpty
              css={itemFullWidthStyle}
              color='text'
              data-test-id='node-tile-menu-start-routing-requests'
              disabled={disabled || !hasPermissions}
              iconType='play'
              onClick={this.onClickStartRoutingRequests}
            >
              {formatMessage(messages.startRoutingRequests)}
            </EuiButtonEmpty>
          ) : (
            <EuiButtonEmpty
              css={itemFullWidthStyle}
              color='text'
              data-test-id='node-tile-menu-stop-routing-requests'
              disabled={disabled || !hasPermissions}
              iconType='stop'
              onClick={this.onClickStopRoutingRequests}
            >
              {formatMessage(messages.stopRoutingRequests)}
            </EuiButtonEmpty>
          )
        }
      </PermissionsGate>
    )

    if (disabled) {
      return (
        <EuiToolTip position='right' content={toolTipContent}>
          {panelContent}
        </EuiToolTip>
      )
    }

    return panelContent
  }

  renderPauseInstanceItem() {
    const {
      deployment,
      instance,
      intl: { formatMessage },
    } = this.props

    const systemOwned = isSystemOwned({ deployment })
    const disabled = this.configurationChangeInProgress || systemOwned
    const isStarted = !isNodePausedByUser(instance)

    let toolTipContent

    if (disabled) {
      if (this.configurationChangeInProgress) {
        toolTipContent = formatMessage(messages.disabledWhileConfigurationInProgress)
      }

      if (systemOwned) {
        if (isStarted) {
          toolTipContent = (
            <FormattedMessage
              id='node-tile-menu.cannot-pause-deployment'
              defaultMessage='You cannot pause a system deployment.'
            />
          )
        }

        if (!isStarted) {
          toolTipContent = (
            <FormattedMessage
              id='node-tile-menu.cannot-resume-deployment'
              defaultMessage='You cannot resume a system deployment.'
            />
          )
        }
      }
    }

    const content = (
      <PermissionsGate
        permissions={[
          {
            type: 'deployment',
            action: 'maintenance',
            id: deployment.id,
          },
        ]}
      >
        {({ hasPermissions }) =>
          isStarted ? (
            <EuiButtonEmpty
              css={itemFullWidthStyle}
              color='text'
              data-test-id='node-tile-menu-pause-instance'
              disabled={disabled || !hasPermissions}
              iconType='pause'
              onClick={this.onClickPauseInstance}
            >
              {formatMessage(messages.pauseInstance)}
            </EuiButtonEmpty>
          ) : (
            <EuiButtonEmpty
              css={itemFullWidthStyle}
              color='text'
              data-test-id='node-tile-menu-resume-instance'
              disabled={disabled || !hasPermissions}
              iconType='play'
              onClick={this.onClickStartInstance}
            >
              {formatMessage(messages.startInstance)}
            </EuiButtonEmpty>
          )
        }
      </PermissionsGate>
    )

    return disabled ? (
      <EuiToolTip position='right' content={toolTipContent}>
        {content}
      </EuiToolTip>
    ) : (
      content
    )
  }

  renderMoveInstanceItem() {
    const {
      intl: { formatMessage },
    } = this.props

    const disabled = this.configurationChangeInProgress

    const content = (
      <EuiButtonEmpty
        css={itemFullWidthStyle}
        color='text'
        data-test-id='node-tile-menu-move-instance'
        disabled={disabled}
        iconType='node'
        onClick={this.onClickMoveInstance}
      >
        {formatMessage(messages.moveInstance)}
      </EuiButtonEmpty>
    )

    return disabled ? (
      <EuiToolTip
        position='right'
        content={formatMessage(messages.disabledWhileConfigurationInProgress)}
      >
        {content}
      </EuiToolTip>
    ) : (
      content
    )
  }

  renderEceInstanceActionItems() {
    const { canMoveNode } = this.props

    return (
      <Fragment>
        {this.renderManageInstanceItems()}

        {canMoveNode && (
          <Fragment>
            <EuiSpacer size='xs' />
            {this.renderMoveInstanceItem()}
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderInstanceCapacityOverrideItem() {
    const {
      intl: { formatMessage },
    } = this.props
    const disabled = this.configurationChangeInProgress

    const content = (
      <EuiButtonEmpty
        css={itemFullWidthStyle}
        color='text'
        data-test-id='node-tile-menu-move-override-capacity'
        disabled={disabled}
        iconType='memory'
        onClick={this.onClickOverrideInstanceSize}
      >
        {formatMessage(messages.overrideInstanceSize)}
      </EuiButtonEmpty>
    )

    return disabled ? (
      <EuiToolTip
        position='right'
        content={formatMessage(messages.disabledWhileConfigurationInProgress)}
      >
        {content}
      </EuiToolTip>
    ) : (
      content
    )
  }

  renderOverrideDiskQuotaItem() {
    const {
      intl: { formatMessage },
    } = this.props
    const disabled = this.configurationChangeInProgress

    const content = (
      <EuiButtonEmpty
        css={itemFullWidthStyle}
        color='text'
        data-test-id='node-tile-menu-move-override-disk-quota'
        disabled={disabled}
        iconType='storage'
        onClick={this.onClickOverrideDiskQuota}
      >
        {formatMessage(messages.overrideDiskQuota)}
      </EuiButtonEmpty>
    )

    return disabled ? (
      <EuiToolTip
        position='right'
        content={formatMessage(messages.disabledWhileConfigurationInProgress)}
      >
        {content}
      </EuiToolTip>
    ) : (
      content
    )
  }

  renderEceOverrideActionItems() {
    const { deployment, instanceCapacityOverride, diskQuotaOverride } = this.props

    return (
      <PermissionsGate
        permissions={[
          {
            type: 'deployment',
            action: 'update',
            id: deployment.id,
          },
        ]}
      >
        <Fragment>
          {instanceCapacityOverride && (
            <Fragment>
              <EuiSpacer size='xs' />
              {this.renderInstanceCapacityOverrideItem()}
            </Fragment>
          )}

          {diskQuotaOverride && this.renderOverrideDiskQuotaItem()}
        </Fragment>
      </PermissionsGate>
    )
  }

  renderEceThreadDumpActionItem() {
    const {
      intl: { formatMessage },
      captureThreadDump,
      deployment,
      kind,
      captureThreadDumpRequest,
    } = this.props

    if (!this.canCaptureDumps(kind)) {
      return null
    }

    return (
      <PermissionsGate
        permissions={[{ type: 'deployment-thread-dump', action: 'get', id: deployment.id }]}
      >
        <CuiButtonEmpty
          data-test-id='node-tile-menu-capture-thread-dump'
          color='text'
          iconType='apmTrace'
          onClick={() =>
            captureThreadDump()
              .catch(captureThreadDumpFailToast)
              .finally(() => this.closePopover())
          }
          spin={captureThreadDumpRequest.inProgress}
        >
          {formatMessage(messages.captureThreadDump)}
        </CuiButtonEmpty>
      </PermissionsGate>
    )
  }

  renderEceHeapDumpActionItems() {
    const {
      intl: { formatMessage },
      deployment,
      startHeapDumpCaptureRequest,
      kind,
      instance,
    } = this.props

    if (!this.canCaptureDumps(kind)) {
      return null
    }

    return (
      <PermissionsGate
        permissions={[{ type: 'deployment-heap-dumps', action: 'capture', id: deployment.id }]}
      >
        <CuiButtonEmpty
          data-test-id='node-tile-menu-capture-heap-dump'
          color='text'
          iconType='inspect'
          onClick={() => this.startCapture()}
          spin={startHeapDumpCaptureRequest.inProgress}
          confirm={true}
          confirmModalProps={{
            title: (
              <FormattedMessage
                id='heapDumps.start-capture-confirmation-title'
                defaultMessage='Capture heap dump?'
              />
            ),
            body: (
              <FormattedMessage
                id='heapDumps.start-capture-confirmation-body'
                defaultMessage='Take a heap dump capture of {sliderName} {instanceId}? The JVM will be paused and unresponsive during a capture.'
                values={{
                  sliderName: (
                    <FormattedMessage {...getSliderPrettyName({ sliderInstanceType: kind })} />
                  ),
                  instanceId: <strong>{instance.instance_name}</strong>,
                }}
              />
            ),
            confirm: (
              <FormattedMessage
                id='heapDumps.start-capture-confirmation-button'
                defaultMessage='Start capture'
              />
            ),
            buttonColor: 'primary',
          }}
        >
          {formatMessage(messages.captureHeapDump)}
        </CuiButtonEmpty>
      </PermissionsGate>
    )
  }

  isConfigurationChangeInProgress() {
    const { resource, disableNodeControlsIfPlanPending } = this.props
    return !!disableNodeControlsIfPlanPending && !!resource.info.plan_info.pending
  }

  getEditPageHref = (hash?: string) => {
    const { deployment } = this.props
    const editUrl = deploymentEditUrl(deployment.id)

    if (hash) {
      return `${editUrl}#${hash}`
    }

    return editUrl
  }

  onClickPauseInstance = () => {
    this.setState({ showPauseInstanceModal: true })
    this.closePopover()
  }

  onConfirmPauseInstance = () => {
    this.setState({ showPauseInstanceModal: false })
    this.props.stopInstance()
  }

  onClickStartInstance = () => {
    this.props.startInstance()
    this.closePopover()
  }

  onClickStopRoutingRequests = () => {
    this.setState({ showStopRoutingRequestsModal: true })
    this.closePopover()
  }

  onConfirmStopRoutingRequests = () => {
    this.setState({ showStopRoutingRequestsModal: false })
    this.props.stopRouting()
  }

  onClickStartRoutingRequests = () => {
    this.props.startRouting()
    this.closePopover()
  }

  onClickMoveInstance = () => {
    this.setState({ showMoveNodeModal: true })
    this.closePopover()
  }

  onClickOverrideInstanceSize = () => {
    this.setState({ showInstanceCapacityModal: true })
    this.closePopover()
  }

  onClickOverrideDiskQuota = () => {
    this.setState({ showDiskQuotaModal: true })
    this.closePopover()
  }

  toggle = () => {
    this.setState((prevState) => ({ isPopoverOpen: !prevState.isPopoverOpen }))
  }

  closePopover = () => {
    this.setState({ isPopoverOpen: false })
  }

  hideMoveNodeModal = () => {
    this.setState({ showMoveNodeModal: false })
  }

  hideInstanceCapacityModal = () => {
    this.setState({ showInstanceCapacityModal: false })
  }

  hideDiskQuotaModal = () => {
    this.setState({ showDiskQuotaModal: false })
  }

  hideStopRoutingRequestsModal = () => {
    this.setState({ showStopRoutingRequestsModal: false })
  }

  hidePauseInstanceModal = () => {
    this.setState({ showPauseInstanceModal: false })
  }

  startCapture() {
    const { startHeapDumpCapture } = this.props

    return startHeapDumpCapture().then(
      startHeapDumpCaptureSuccessToast,
      startHeapDumpCaptureFailToast,
    )
  }

  canCaptureDumps(kind: SliderInstanceType) {
    return kind === 'elasticsearch' || kind === 'enterprise_search'
  }
}

const captureThreadDumpFailToast = () =>
  addToast({
    id: 'captureThreadDumpFail',
    family: 'threadDumps.capture.fail',
    iconType: 'apmTrace',
    title: <FormattedMessage id='threadDumps.capture.title' defaultMessage='Thread dump capture' />,
    text: (
      <FormattedMessage
        id='threadDumps.capture.fail-text'
        defaultMessage='Failed to capture thread dump.'
      />
    ),
    color: 'danger',
  })

export default injectIntl(NodeTileMenu)
