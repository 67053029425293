/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect, useRef } from 'react'

import { useEbtContext } from '@modules/ebt/EbtContext'
import { EventRegistry } from '@modules/ebt/EventRegistry'
import type { Region as StackDeploymentRegion } from '@modules/ui-types'

import type { ElasticsearchOptimizedFor } from '../../project-user-api/v1/types'
import type { Region } from '../../cluster-user-api/v1/types'
import type {
  ExperienceLevelOptionsType,
  ResourceType,
  TrialIntentOptionsType,
  UseCaseOptionsType,
} from '../../discovery-questions-lib/utils'

type OnboardingEventType =
  | 'full_name'
  | 'experience_level'
  | 'trial_intent'
  | 'use_case'
  | 'resource'
  | 'project_subtype'
  | 'kibana'

interface OnboardingGenericEventSchema<T extends OnboardingEventType> {
  answer: T extends 'experience_level'
    ? ExperienceLevelOptionsType
    : T extends 'trial_intent'
    ? TrialIntentOptionsType
    : T extends 'use_case'
    ? UseCaseOptionsType
    : T extends 'full_name'
    ? undefined | null
    : T extends 'resource'
    ?
        | {
            region: StackDeploymentRegion
            version: string
            platform: string
            hardware: string
          }
        | {
            region: Region
          }
    : T extends 'project_subtype'
    ? ElasticsearchOptimizedFor
    : T extends 'kibana'
    ? string
    : never
  resource: ResourceType
}

interface UseEbtOnboarding {
  reportEbtEvent: <
    TEvent extends OnboardingEventType,
    TArgs extends OnboardingGenericEventSchema<TEvent>,
  >(
    type: TEvent,
    args: TArgs,
  ) => void
}

export const useEbtOnboarding = (): UseEbtOnboarding => {
  const { analytics } = useEbtContext()
  const hasBeenRegister = useRef(false)

  useEffect(() => {
    if (analytics && !hasBeenRegister.current) {
      hasBeenRegister.current = true

      try {
        const projectSubtypeRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'project_subtype'>
        >({
          analytics,
          eventType: 'project_subtype',
          schemaProps: {
            answer: { description: 'project sub type for search' },
            resource: { description: 'serverless or stateful' },
          },
        })

        const fullNameRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'full_name'>
        >({
          analytics,
          eventType: 'full_name',
          schemaProps: {
            answer: { description: 'user info' },
            resource: { description: 'serverless or stateful' },
          },
        })

        const resourceRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'resource'>
        >({
          analytics,
          eventType: 'resource',
          schemaProps: {
            answer: {
              description: 'which resource has been used region/platform/hardware/version',
            },
            resource: { description: 'serverless or stateful' },
          },
        })

        const experienceLevelRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'experience_level'>
        >({
          analytics,
          eventType: 'experience_level',
          schemaProps: {
            answer: { description: 'The experience level of a user' },
            resource: { description: 'serverless or stateful' },
          },
        })

        const trialIntentRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'trial_intent'>
        >({
          analytics,
          eventType: 'trial_intent',
          schemaProps: {
            answer: { description: 'The user intent for starting a trial' },
            resource: { description: 'serverless or stateful' },
          },
        })
        const useCaseRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'use_case'>
        >({
          analytics,
          eventType: 'use_case',
          schemaProps: {
            answer: { description: 'The use case selected by the user' },
            resource: { description: 'serverless or stateful' },
          },
        })

        const kibanaRegistry = new EventRegistry<
          OnboardingEventType,
          OnboardingGenericEventSchema<'kibana'>
        >({
          analytics,
          eventType: 'kibana',
          schemaProps: {
            answer: { description: 'Did the user went to kibana?' },
            resource: { description: 'serverless or stateful' },
          },
        })

        projectSubtypeRegistry.registerEvent()
        fullNameRegistry.registerEvent()
        resourceRegistry.registerEvent()
        experienceLevelRegistry.registerEvent()
        trialIntentRegistry.registerEvent()
        useCaseRegistry.registerEvent()
        kibanaRegistry.registerEvent()
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }
    }
  }, [analytics])

  const reportEbtEvent: UseEbtOnboarding['reportEbtEvent'] = (type, args) => {
    try {
      if (analytics) {
        analytics.reportEvent(type, args)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { reportEbtEvent }
}
