/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { css } from '@emotion/react'

export const costChartPanelCss = (theme) =>
  css({
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [`@media screen and (max-width: ${theme.euiTheme.breakpoint.m}px)`]: {
      height: 200,
    },
  })

export const billingResponsivePanelCss = css`
  overflow: auto;

  .euiTableHeaderMobile {
    display: none;
  }

  .euiTable {
    min-width: 700px;

    // nested tables
    .euiTable {
      min-width: auto;
      width: '100%';
    }
  }
`
