/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import cx from 'classnames'
import React, { PureComponent, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'

import type { DeploymentsSearchResponse, SearchRequest } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, ProfileState } from '@modules/ui-types'
import { withErrorBoundary } from '@modules/cui/Boundary'
import SupplyProjectsMetadata from '@modules/project-user-components/SupplyProjectsMetadata'
import {
  CuiFeatureFlagGate,
  CuiFeatureDisabled,
  CuiFeatureEnabled,
} from '@modules/cui/FeatureFlagGate'
import Feature from '@modules/utils/feature'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import AcceptOrganizationInvitation from '@/components/Organization/AcceptOrganizationInvitation'
import { inActiveTrial } from '@/lib/trial'

import BlogsTile from '../BlogsTile'
import CloudStatusTile from '../CloudStatusTile'
import ElasticsearchServiceTile from '../ElasticsearchServiceTile'
import ProjectsPortalPanel from '../ProjectsPortalPanel'
import PortalErrors from '../PortalErrors'
import PortalPage from '../PortalPage'
import TrainingTile from '../TrainingTile'
import CommunityTile from '../CommunityTile'
import SupportTile from '../SupportTile'
import searchDeploymentsQuery from '../../../StackDeploymentSearch/searchDeploymentsQuery'
import TrialExpiredTile from '../TrialExpiredTile'
import TrialStatusCallout from '../TrialStatusCallout'

import type { ReactElement } from 'react'
import type { InvitationType } from '@/apps/userconsole/components/AcceptInvitation/types'

export interface Props {
  hasCloudStatusTile: boolean
  deletedDeploymentIds: string[]
  searchDeployments: (query: SearchRequest) => void
  fetchProfile: () => Promise<any>
  profile: ProfileState
  searchResults: DeploymentsSearchResponse | null
  searchResultsRequest: AsyncRequestState
  isRouteFSTraced?: boolean
  invitationType: InvitationType | null
  invitationToken: string | null
  invitationOrganizationId: string | null
  invitationOrganizationName: string | null
  showOrganization: boolean
}

class PortalLandingPage extends PureComponent<Props> {
  componentDidMount() {
    const { fetchProfile } = this.props

    fetchProfile()

    // Need to kick this off manually once because we are blocking the render of
    // the deployment panel -- which ordinarily polls -- until we know whether
    // there ARE any deployments, due to projects/deployments rendering order.
    this.search()
  }

  render() {
    const { isRouteFSTraced } = this.props
    return (
      <Fragment>
        {this.renderOrganizationInvitationModal()}

        <PortalPage className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-landing-page')}>
          <PortalErrors spacerAfter={true} />

          {this.renderPortalContents()}
        </PortalPage>
      </Fragment>
    )
  }

  renderPortalContents() {
    const { profile, hasCloudStatusTile } = this.props
    const { inTrial, hasExpiredTrial } = profile ?? {}
    const deployments = this.getDeployments()

    return (
      <EuiFlexGroup direction='column'>
        <EuiFlexItem>
          <EuiTitle size='s'>
            <h1>
              <FormattedMessage
                id='portal-landing-page.title'
                defaultMessage='Welcome to Elastic Cloud'
              />
            </h1>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup gutterSize='l' alignItems='flexStart' responsive={true}>
            <EuiFlexItem grow={2}>
              <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                  <CuiFeatureFlagGate feature={Feature.serverless} ldFlag='showServerlessFlag'>
                    <CuiFeatureEnabled>
                      <SupplyProjectsMetadata>
                        {({ projectsLength }) => {
                          if (
                            inTrial &&
                            hasExpiredTrial &&
                            isEmpty(deployments) &&
                            !projectsLength
                          ) {
                            return <TrialExpiredTile />
                          }

                          return (
                            <EuiFlexGroup direction='column'>
                              {this.renderTrialStatusCallout()}
                              <EuiFlexItem>{this.renderElasticsearchServiceTile()}</EuiFlexItem>
                              <EuiFlexItem>
                                <ProjectsPortalPanel />
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          )
                        }}
                      </SupplyProjectsMetadata>
                    </CuiFeatureEnabled>

                    <CuiFeatureDisabled>
                      <EuiFlexGroup direction='column'>
                        {!isEmpty(deployments) && (
                          <EuiFlexItem>{this.renderTrialStatusCallout()}</EuiFlexItem>
                        )}

                        <EuiFlexItem>{this.renderElasticsearchServiceTile()}</EuiFlexItem>
                      </EuiFlexGroup>
                    </CuiFeatureDisabled>
                  </CuiFeatureFlagGate>
                </EuiFlexItem>

                <EuiFlexItem>
                  <EuiFlexGroup responsive={true} gutterSize='l'>
                    <EuiFlexItem grow={1}>
                      <SupportTile />
                    </EuiFlexItem>

                    <EuiFlexItem grow={1}>
                      <TrainingTile inTrial={inTrial} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFlexGroup direction='column' gutterSize='none'>
                {hasCloudStatusTile && !isEmpty(deployments) && (
                  <EuiFlexItem>
                    <CloudStatusTile />
                  </EuiFlexItem>
                )}

                <EuiFlexItem>
                  <BlogsTile />

                  <EuiSpacer size='l' />
                </EuiFlexItem>

                <EuiFlexItem>
                  <CommunityTile />

                  <EuiSpacer size='l' />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderElasticsearchServiceTile(): ReactElement {
    const { profile, searchResultsRequest, invitationToken } = this.props

    return (
      <PermissionsGate permissions={[{ type: 'deployment', action: 'search' }]}>
        <ElasticsearchServiceTile
          profile={profile}
          deployments={this.getDeployments()}
          search={this.search}
          isPollingEnabled={invitationToken === null}
          isSearching={searchResultsRequest.inProgress}
        />
      </PermissionsGate>
    )
  }

  renderOrganizationInvitationModal(): ReactElement | null {
    const {
      invitationType,
      invitationToken,
      invitationOrganizationId,
      invitationOrganizationName,
      profile,
      showOrganization,
    } = this.props

    if (profile === null || !showOrganization) {
      return null
    }

    const { organization_id: organizationId } = profile

    if (
      invitationType === 'organization' &&
      invitationToken &&
      invitationOrganizationId &&
      invitationOrganizationName
    ) {
      return (
        <AcceptOrganizationInvitation
          userOrganizationId={organizationId}
          invitationToken={invitationToken}
          invitationOrganizationId={invitationOrganizationId}
          invitationOrganizationName={invitationOrganizationName}
          search={this.search}
        />
      )
    }

    return null
  }

  renderTrialStatusCallout() {
    const { profile } = this.props

    if (!profile) {
      return null
    }

    if (!inActiveTrial({ profile })) {
      return null
    }

    const trialDaysRemaining = profile.trialDaysRemaining || 0

    if (trialDaysRemaining > 3) {
      return null
    }

    return <TrialStatusCallout trialDaysRemaining={trialDaysRemaining} />
  }

  getDeployments() {
    const { searchResults } = this.props
    return searchResults ? searchResults.deployments : null
  }

  search = () => {
    const { searchDeployments, deletedDeploymentIds } = this.props

    const query = searchDeploymentsQuery({
      deletedDeploymentIds,
    })

    searchDeployments(query)
  }
}

export default withErrorBoundary(PortalLandingPage)
