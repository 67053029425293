/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import type { SolutionType } from './types'
import type { ReactNode } from 'react'

type SolutionMessage = {
  label: SolutionType
  logo: string
  title: ReactNode
  description: ReactNode
  features: ReactNode[]
}

export function useSolutionOptions(): SolutionMessage[] {
  const [isFlagsUsable, { includesElasticsearchBadge }] = useFlagsWhenLoaded()

  const isBadgeEnabled = isFlagsUsable && includesElasticsearchBadge

  return [
    {
      label: 'elasticsearch',
      logo: 'logoElasticsearch',
      title: <FormattedMessage defaultMessage='Elasticsearch' id='select-solution.title-es' />,
      description: isBadgeEnabled ? (
        <FormattedMessage
          defaultMessage='Build search & vector database applications'
          id='select-solution.description-es-b'
        />
      ) : (
        <FormattedMessage
          defaultMessage='Build custom applications with your data'
          id='select-solution.description-es-a'
        />
      ),
      features: [
        isBadgeEnabled ? (
          <FormattedMessage
            defaultMessage='{title} APIs to create search experiences, easily'
            id='select-solution.description-es-feature0-b'
            values={{
              title: (
                <strong>
                  <FormattedMessage
                    id='select-solution.description-es-title'
                    defaultMessage='Build.'
                  />
                </strong>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage='{title} Create search experiences, easily'
            id='select-solution.description-es-feature0-a'
            values={{
              title: (
                <strong>
                  <FormattedMessage
                    id='select-solution.description-es-title'
                    defaultMessage='Build.'
                  />
                </strong>
              ),
            }}
          />
        ),
        <FormattedMessage
          defaultMessage='{title} Scalable hybrid and vector database to find relevant results, fast'
          id='select-solution.description-es-feature1'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-es-feature1-title'
                  defaultMessage='Search.'
                />
              </strong>
            ),
          }}
        />,
        <FormattedMessage
          defaultMessage='{title} Search, explore and create visual analysis'
          id='select-solution.description-es-feature2'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-es-feature2-title'
                  defaultMessage='Explore.'
                />
              </strong>
            ),
          }}
        />,
        <FormattedMessage
          defaultMessage='{title} Complete ML tools to power insights, investigation and AI apps'
          id='select-solution.description-es-feature3'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-es-feature3-title'
                  defaultMessage='AI-ML.'
                />
              </strong>
            ),
          }}
        />,
      ],
    },
    {
      label: 'observability',
      logo: 'logoObservability',

      title: isBadgeEnabled ? (
        <FormattedMessage
          defaultMessage='Elastic for Observability'
          id='select-solution.title-elastic-for-observability'
        />
      ) : (
        <FormattedMessage defaultMessage='Observability' id='select-solution.title-observability' />
      ),

      description: (
        <FormattedMessage
          defaultMessage='Monitor the health of your applications'
          id='select-solution.description-observability'
        />
      ),
      features: [
        <FormattedMessage
          defaultMessage='{title} Search and analyze log data, at scale'
          id='select-solution.description-observability-feature0'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-observability-feature0-title'
                  defaultMessage='Logs.'
                />
              </strong>
            ),
          }}
        />,
        <FormattedMessage
          defaultMessage='{title} ML-powered log spike and pattern analysis, change and anomaly detection'
          id='select-solution.description-observability-feature1'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-observability-feature1-title'
                  defaultMessage='AIOps.'
                />
              </strong>
            ),
          }}
        />,
        <FormattedMessage
          defaultMessage='{title} Measure and monitor service-level objectives and error budgets over time'
          id='select-solution.description-observability-feature2'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-observability-feature2-title'
                  defaultMessage='SLO.'
                />
              </strong>
            ),
          }}
        />,
        <FormattedMessage
          defaultMessage='{title} Traces, logs, metrics, service maps, dependencies, and correlation analysis'
          id='select-solution.description-observability-feature3'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-observability-feature3-title'
                  defaultMessage='APM.'
                />
              </strong>
            ),
          }}
        />,
        <FormattedMessage
          defaultMessage='{title}. Git-ops based simulated end user interactions to identify and resolve issues on your web-based applications'
          id='select-solution.description-observability-feature4'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-observability-feature4-title'
                  defaultMessage='Synthetics monitoring'
                />
              </strong>
            ),
          }}
        />,
      ],
    },
    {
      label: 'security',
      logo: 'logoSecurity',
      title: isBadgeEnabled ? (
        <FormattedMessage
          defaultMessage='Elastic for Security'
          id='select-solution.title-elastic-for-security'
        />
      ) : (
        <FormattedMessage defaultMessage='Security' id='select-solution.title-security' />
      ),

      description: (
        <FormattedMessage
          defaultMessage='Detect threats and protect your systems '
          id='select-solution.description-security'
        />
      ),

      features: getSecurityFeatures(isBadgeEnabled),
    },
  ]
}

const getSecurityFeatures = (isBadgeEnabled: boolean) => [
  ...(isBadgeEnabled
    ? [
        <FormattedMessage
          defaultMessage='{title} Collect, search, and analyze security logs'
          id='select-solution.description-security-feature0'
          values={{
            title: (
              <strong>
                <FormattedMessage
                  id='select-solution.description-security-feature0-title'
                  defaultMessage='Logs.'
                />
              </strong>
            ),
          }}
        />,
      ]
    : []),
  ...[
    isBadgeEnabled ? (
      <FormattedMessage
        defaultMessage='{title} Detect, investigate, and respond to evolving threats'
        id='select-solution.description-security-feature1-b'
        values={{
          title: (
            <strong>
              <FormattedMessage
                id='select-solution.description-security-feature1-title'
                defaultMessage='SIEM.'
              />
            </strong>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        defaultMessage='{title} Detect, investigate, and respond to evolving threats in your environment'
        id='select-solution.description-security-feature1-a'
        values={{
          title: (
            <strong>
              <FormattedMessage
                id='select-solution.description-security-feature1-title'
                defaultMessage='SIEM.'
              />
            </strong>
          ),
        }}
      />
    ),
    isBadgeEnabled ? (
      <FormattedMessage
        defaultMessage='{title} Protect your hosts against malware, ransomware, and other threats with Elastic Agent and Defend'
        id='select-solution.description-security-feature2-b'
        values={{
          title: (
            <strong>
              <FormattedMessage
                id='select-solution.description-security-feature2-title'
                defaultMessage='Endpoint Security.'
              />
            </strong>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        defaultMessage='{title} Prevent, collect, detect and respond - all with Elastic Agent'
        id='select-solution.description-security-feature2-a'
        values={{
          title: (
            <strong>
              <FormattedMessage
                id='select-solution.description-security-feature2-title'
                defaultMessage='Endpoint Security.'
              />
            </strong>
          ),
        }}
      />
    ),
    <FormattedMessage
      defaultMessage='{title} Assess your cloud posture and protect your workloads from attacks'
      id='select-solution.description-security-feature3'
      values={{
        title: (
          <strong>
            <FormattedMessage
              id='select-solution.description-security-feature3-title'
              defaultMessage='Cloud Protection.'
            />
          </strong>
        ),
      }}
    />,
  ],
]
