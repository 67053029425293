/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiHorizontalRule } from '@elastic/eui'

import type { Price } from '@modules/billing-api/v1/types'

import PriceList from '../PriceList'
import { getElasticsearchPrices } from '../utils'

interface ElasticsearchTabProps {
  prices: Price[]
  isLoading: boolean
  addonList: JSX.Element
}

const ElasticsearchTab = ({ prices, isLoading, addonList }: ElasticsearchTabProps) => (
  <Fragment>
    <PriceList prices={getElasticsearchPrices(prices)} isLoading={isLoading} />
    <EuiHorizontalRule margin='xxl' />
    {addonList}
  </Fragment>
)

export default ElasticsearchTab
