/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { EuiFlexGroup } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'
import { isDefaultSolutionView } from '@modules/deployment-creation-wizard/types'

import SolutionViewHeader from '@/components/StackDeploymentEditor/CreateStackDeploymentRoute/SolutionViewHeader'
import StackDeploymentHealthProblems from '@/components/StackDeploymentHealthProblems'

import type { FC } from 'react'

type DeploymentGettingStartedHeaderProps = {
  solutionView: string | undefined
  planInProgress: boolean
  createFailed: boolean
  stackDeployment: (StackDeployment | undefined) & StackDeployment
}

const DeploymentGettingStartedHeader: FC<DeploymentGettingStartedHeaderProps> = ({
  solutionView,
  planInProgress,
  createFailed,
  stackDeployment,
}) => (
  <EuiFlexGroup justifyContent='spaceBetween'>
    {isDefaultSolutionView(solutionView) ? (
      <SolutionViewHeader solutionView={solutionView} />
    ) : null}
    {planInProgress && !createFailed && (
      <StackDeploymentHealthProblems
        onGettingStartedPage={true}
        deployment={stackDeployment}
        spacerAfter={false}
        linkRecentChanges={true}
        hideActivityBits={true}
      />
    )}
  </EuiFlexGroup>
)

export default DeploymentGettingStartedHeader
