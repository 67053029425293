/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from '@elastic/eui'

import type { SliderInstanceType } from '@modules/ui-types'
import CopyButton from '@modules/cui/CopyButton'

import ExternalLink from '../../ExternalLink'

import type { MessageDescriptor, WrappedComponentProps } from 'react-intl'
import type { FunctionComponent, ReactNode } from 'react'

interface ApplicationLinkProps {
  id: string
  appKey?: SliderInstanceType | 'fleet'
  label: MessageDescriptor
  uiUri: string | undefined
  apiUri: string | undefined
  appID: string
  helpPopover?: ReactNode
  available: boolean
  showLaunchLink: boolean
}

type Props = ApplicationLinkProps & WrappedComponentProps

const ApplicationLink: FunctionComponent<Props> = ({
  intl: { formatMessage },
  appKey,
  label,
  uiUri,
  apiUri,
  appID,
  available,
  showLaunchLink,
  helpPopover,
}) => {
  const formattedLabel = formatMessage(label)
  const openMessage = formatMessage({ id: 'application-link.launch', defaultMessage: 'Open' })
  const endpointMessage = formatMessage({
    id: 'application-link.copy-endpoint',
    defaultMessage: 'Copy endpoint',
  })
  const copyClusterIdMessage = formatMessage({
    id: 'application-link.copy-id.elasticsearch',
    defaultMessage: 'Copy cluster ID',
  })

  const endpointAriaLabel = formatMessage(
    {
      id: 'application-link.copy-endpoint.aria-label',
      defaultMessage: 'Copy "{formattedLabel}" endpoint',
    },
    { formattedLabel },
  )
  const componentIdAriaLabel = formatMessage(
    {
      id: 'application-link.copy-id.other.aria-label',
      defaultMessage: 'Copy "{formattedLabel}" component ID',
    },
    { formattedLabel },
  )

  const { euiTheme } = useEuiTheme()

  const disabledLinkStyle = css({
    color: euiTheme.colors.lightShade,
  })

  return (
    <Fragment>
      <div>
        <EuiFlexGroup gutterSize='xs' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiText size='s'>
              <strong>{formattedLabel}</strong>
            </EuiText>
          </EuiFlexItem>

          {helpPopover && <EuiFlexItem grow={false}>{helpPopover}</EuiFlexItem>}
        </EuiFlexGroup>
      </div>

      <div data-test-id={`application-link-copy-${appKey}`}>
        {available && apiUri ? (
          <CopyButton asLink={true} value={apiUri} aria-label={endpointAriaLabel}>
            {endpointMessage}
          </CopyButton>
        ) : (
          <EuiText size='s' css={disabledLinkStyle} aria-hidden='true'>
            {endpointMessage}
          </EuiText>
        )}
      </div>

      <div data-test-id={`application-link-copy-id-${appKey}`}>
        {appKey === `elasticsearch` ? (
          <CopyButton asLink={true} value={appID} aria-label={copyClusterIdMessage}>
            {copyClusterIdMessage}
          </CopyButton>
        ) : (
          <CopyButton asLink={true} value={appID} aria-label={componentIdAriaLabel}>
            <FormattedMessage
              id='application-link.copy-id.other'
              defaultMessage='Copy component ID'
            />
          </CopyButton>
        )}
      </div>

      {appKey !== `elasticsearch` && showLaunchLink && (
        <div data-test-id={`application-link-launch-${appKey}`}>
          {available ? (
            <ExternalLink href={uiUri} target={'_self'}>
              {openMessage}
            </ExternalLink>
          ) : (
            <EuiText size='s' css={disabledLinkStyle} aria-hidden='true'>
              {openMessage}
            </EuiText>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default injectIntl(ApplicationLink)
