/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiLink,
  useEuiTheme,
} from '@elastic/eui'

import {
  useGetCostsInstancesV2,
  useGetCostsItemsV2,
  useGetUsageChartsDataV2,
} from '@modules/billing-lib/hooks'
import { processQueryParams } from '@modules/billing-api/utils'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'
import { useConfig } from '@modules/cui/ConfigContext'
import { useServerlessGroupBy } from '@modules/billing-lib/featureflags'

import { ViewByToBucketingStrategy } from '@/components/User/BillingUsage/constants'
import CostsChart from '@/components/User/BillingUsage/components/CostsChart'
import ProductChart from '@/components/User/BillingUsage/components/ProductChart'
import MainFilters from '@/components/User/BillingUsage/components/Filters/MainFilters'
import SideFilters from '@/components/User/BillingUsage/components/Filters/SideFilters'

import ErrorCallout from '../components/ErrorCallout'
import { Action } from '../components/Filters/filtersReducer'
import { stringifyFilters, getValidationError } from '../components/Filters/utils'
import { ProductType, GroupBy } from '../types'
import withFiltersProvider from '../components/Filters/withFiltersProvider'
import useFilters from '../components/Filters/useFilters'
import { costChartPanelCss } from '../styles'

import {
  getDeploymentInstances,
  getProjectInstances,
  filterProjectsInstancesBySolutions,
  getChartDataProductTypes,
  getDateRange,
} from './utils'
import DeploymentsList from './DeploymentsList'
import ProjectsList from './ProjectsList'
import ProductsList from './ProductsList'

import type { Props } from './types'
import type { FunctionComponent } from 'react'

const BillingUsageOverviewV2: FunctionComponent<Props> = ({ organizationId, setInstanceName }) => {
  const history = useHistory()
  const appName = useConfig('APP_NAME')
  const { filters, dispatch } = useFilters()
  const theme = useEuiTheme()

  const areDatesValid = !getValidationError({
    startDate: filters.startDate,
    endDate: filters.endDate,
    viewBy: filters.viewBy,
  })

  const costsInstancesQuery = useGetCostsInstancesV2(
    {
      pathParameters: {
        organization_id: organizationId,
      },
      queryParameters: processQueryParams({ ...getDateRange(filters), include_names: true }),
    },
    {
      enabled: filters.groupBy === GroupBy.Instance && areDatesValid,
    },
  )

  const costsChartDataQuery = useGetUsageChartsDataV2(
    {
      pathParameters: {
        organization_id: organizationId,
      },
      queryParameters: processQueryParams({
        ...getDateRange(filters),
        instance_type: 'all',
        bucketing_strategy: ViewByToBucketingStrategy[filters.viewBy],
      }),
    },
    {
      enabled: filters.productTypes.length > 0 && areDatesValid,
    },
  )

  const costsItemsV2Query = useGetCostsItemsV2(
    {
      pathParameters: { organization_id: organizationId },
      queryParameters: processQueryParams({
        ...getDateRange(filters),
        serverless_group_by: useServerlessGroupBy(),
      }),
    },
    {
      enabled: filters.groupBy === GroupBy.Product && areDatesValid,
    },
  )

  const instances = costsInstancesQuery.data?.instances || []
  const deploymentInstances = getDeploymentInstances(instances)
  const projectInstances = getProjectInstances(instances)
  const filteredProjectInstances = filterProjectsInstancesBySolutions(
    projectInstances,
    filters.solutions,
  )
  const types = getChartDataProductTypes({
    deployments: deploymentInstances,
    projects: filteredProjectInstances,
    selectedProductTypes: filters.productTypes,
  })
  const shouldShowServerlessFilters = true

  // Each time the filters change we want to update queryParams
  useEffect(() => {
    colorForInstances.reset()
    const queryString = stringifyFilters(filters)
    history.push(`${history.location.pathname}?${queryString}`)
  }, [history, filters])

  const onInstanceSelected = (instanceId: string, instanceName?: string) => {
    setInstanceName(instanceName)
    dispatch({ type: Action.SET_INSTANCE_ID, payload: { instanceId } })
  }

  const renderInstanceLists = () => {
    if (costsInstancesQuery.isError) {
      return <ErrorCallout retry={costsInstancesQuery.refetch} data-test-id='usage-table-error' />
    }

    return (
      <Fragment>
        {filters.productTypes.includes(ProductType.Projects) && (
          <ProjectsList
            aggregated={types.length === 2}
            instances={filteredProjectInstances}
            isLoading={costsInstancesQuery.isLoading}
            onProjectSelected={onInstanceSelected}
            organizationId={organizationId}
          />
        )}

        <EuiSpacer size='xl' />

        {filters.productTypes.includes(ProductType.Deployments) && (
          <DeploymentsList
            aggregated={types.length === 2}
            instances={deploymentInstances}
            isLoading={costsInstancesQuery.isLoading}
            onDeploymentSelected={onInstanceSelected}
            organizationId={organizationId}
          />
        )}
      </Fragment>
    )
  }

  const renderProductList = () => {
    if (costsItemsV2Query.isError) {
      return <ErrorCallout retry={costsItemsV2Query.refetch} data-test-id='usage-table-error' />
    }

    const products = costsItemsV2Query.data?.products || []

    return <ProductsList products={products} isLoading={costsItemsV2Query.isLoading} />
  }

  const renderChart = () => {
    if (costsChartDataQuery.isError) {
      return <ErrorCallout data-test-id='costs-chart-error' retry={costsChartDataQuery.refetch} />
    }

    const isProductTypeSelected = !!filters.productTypes.length
    const chartData = isProductTypeSelected ? costsChartDataQuery.data?.data : []
    const shouldRenderDataDisclaimer = filters.groupBy === GroupBy.Product
    const chartComponent = {
      [GroupBy.Product]: (
        <ProductChart data={chartData || []} isLoading={costsChartDataQuery.isLoading} />
      ),
      [GroupBy.Instance]: (
        <CostsChart
          data={chartData || []}
          instanceTypes={types}
          isLoading={costsChartDataQuery.isLoading}
        />
      ),
    }

    return (
      <Fragment>
        <EuiPanel
          hasShadow={false}
          paddingSize='m'
          hasBorder={true}
          data-test-id='costs-chart-panel'
          css={costChartPanelCss(theme)}
        >
          {chartComponent[filters.groupBy]}
        </EuiPanel>

        {shouldRenderDataDisclaimer && (
          <Fragment>
            <EuiSpacer size='s' />

            <EuiText size='s'>
              <FormattedMessage
                id='usage.overview.addons-support.disclaimer'
                defaultMessage='Serverless add-ons and support are included in the serverless product totals.'
              />
            </EuiText>
          </Fragment>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiFlexGroup data-test-id='billing-usage'>
        <EuiFlexItem grow={10}>
          <MainFilters
            isLoading={costsInstancesQuery.isLoading}
            organizationId={organizationId}
            shouldShowServerlessFilters={shouldShowServerlessFilters}
          />
          <EuiSpacer size='m' />
          {renderChart()}
        </EuiFlexItem>

        <SideFilters
          isLoading={costsInstancesQuery.isLoading}
          shouldShowServerlessFilters={shouldShowServerlessFilters}
        />
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      {
        {
          [GroupBy.Instance]: renderInstanceLists(),
          [GroupBy.Product]: renderProductList(),
        }[filters.groupBy]
      }
      <EuiSpacer size='s' />
      <EuiText size='s'>
        <FormattedMessage
          id='billing-usage.bill.disclaimer'
          defaultMessage='This is not your final bill, as it does not include credits, prepaids, promotional offers, or any other discounts. Check your <history>invoice</history> for the final bill.'
          values={{
            history: (content) => (
              <EuiLink
                onClick={() => {
                  const page = appName === 'adminconsole' ? 'billing-history' : 'history'
                  history.push(page)
                }}
              >
                {content}
              </EuiLink>
            ),
          }}
        />
      </EuiText>
    </Fragment>
  )
}

export default withFiltersProvider<Props>(BillingUsageOverviewV2)
