/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, Suspense, lazy, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiOverlayMask, EuiToolTip } from '@elastic/eui'

import type { BillingSubscriptionLevel } from '@modules/ui-types/profile'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import { isDrawdown, isInactive } from '@modules/billing-lib/billingDetails/utils'
import { useConfig } from '@modules/cui/ConfigContext'

import type { ReactElement, ReactNode } from 'react'

const SelectSubscriptionModalLazy = lazy(
  () => import('@/apps/userconsole/components/Billing/SelectSubscription'),
)

const ReviewSubscriptionChangesModalLazy = lazy(
  () => import('@/apps/userconsole/components/Billing/ReviewSubscriptionChangesModal'),
)

export interface Props {
  buttonText: ReactElement
  isButtonDisabled?: boolean
}

const SubscriptionManager = ({ buttonText, isButtonDisabled = false }: Props) => {
  const [isSelectSubscriptionModalOpen, setIsSelectSubscriptionModalOpen] = useState<boolean>(false)
  const [isUsageModalOpen, setIsUsageModalOpen] = useState<boolean>(false)
  const [upgradeLevel, setUpgradeLevel] = useState<BillingSubscriptionLevel | undefined>()
  const billingDetails = useBillingDetails()
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  const isChangeSubscriptionDisabled =
    !billingDetails.data || isInactive(billingDetails.data) || isDrawdown(billingDetails.data)

  const getChangeSubscriptionDisabledReason = (): string | ReactNode => {
    if (!billingDetails.data) {
      return ''
    }

    if (isAdminConsole) {
      return (
        <FormattedMessage
          id='customer-only-action.disabled-help-text'
          defaultMessage='This action can only be performed by customers.'
        />
      )
    }

    if (isInactive(billingDetails.data)) {
      return (
        <FormattedMessage
          id='billing-subscription.change-plan.disabled.inactive'
          defaultMessage="You can't change the subscription level for an inactive account."
        />
      )
    }

    if (isDrawdown(billingDetails.data)) {
      return (
        <FormattedMessage
          id='billing-subscription.change-plan.disabled.drawdown'
          defaultMessage='Your current contract does not support subscription level changes.'
        />
      )
    }

    return ''
  }

  return (
    <Fragment>
      <EuiToolTip content={getChangeSubscriptionDisabledReason()}>
        <EuiButton
          disabled={isButtonDisabled || isChangeSubscriptionDisabled}
          color='text'
          onClick={() => setIsSelectSubscriptionModalOpen(true)}
          data-test-id='subscription-details-button'
        >
          {buttonText}
        </EuiButton>
      </EuiToolTip>

      {isSelectSubscriptionModalOpen && (
        <Suspense fallback={<EuiOverlayMask />} data-test-id='selectSubscriptionModal'>
          <SelectSubscriptionModalLazy
            upgradeLevel={upgradeLevel}
            closeModal={() => {
              setIsSelectSubscriptionModalOpen(false)
              setUpgradeLevel(undefined)
            }}
            onSeeReviewChanges={() => {
              setIsSelectSubscriptionModalOpen(false)
              setIsUsageModalOpen(true)
            }}
          />
        </Suspense>
      )}

      {isUsageModalOpen && (
        <Suspense fallback={<EuiOverlayMask />} data-test-id='confirmSubscriptionModal'>
          <ReviewSubscriptionChangesModalLazy
            closeModal={() => setIsUsageModalOpen(false)}
            onUpgrade={(level) => {
              setIsUsageModalOpen(false)
              setIsSelectSubscriptionModalOpen(true)
              setUpgradeLevel(level)
            }}
          />
        </Suspense>
      )}
    </Fragment>
  )
}

export default SubscriptionManager
