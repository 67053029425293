/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { EuiStepsHorizontalProps } from '@elastic/eui'
import { EuiFlexGroup, EuiStepsHorizontal } from '@elastic/eui'

import Header from '@modules/cui/Header'

import TitleAndDescription from './TitleAndDescription'

import type { ComponentProps, FC, ReactNode } from 'react'

type Breadcrumbs = Exclude<ComponentProps<typeof Header>['breadcrumbs'], undefined>

type CreateDeploymentWizardLayoutProps = {
  breadcrumbs: Breadcrumbs
  steps: EuiStepsHorizontalProps['steps']
  title: ReactNode
  description: ReactNode
}

const DeploymentCreationWizardLayout: FC<CreateDeploymentWizardLayoutProps> = ({
  breadcrumbs,
  steps,
  title,
  description,
  children,
}) => (
  <EuiFlexGroup direction='column' gutterSize='xl'>
    <Header breadcrumbs={breadcrumbs} />
    <EuiStepsHorizontal steps={steps} />
    <EuiFlexGroup alignItems='center' direction='column'>
      <TitleAndDescription title={title} description={description} />
      {children}
    </EuiFlexGroup>
  </EuiFlexGroup>
)

export default DeploymentCreationWizardLayout
