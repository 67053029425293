/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { capitalize } from 'lodash'
import { Fragment } from 'react'

import {
  EuiText,
  EuiTitle,
  EuiPanel,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSkeletonLoading,
} from '@elastic/eui'

import type { BillingSubscriptionLevel } from '@modules/ui-types'
import { getSupportNameBasedOnLevel } from '@modules/billing-lib/supportName'
import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'

import waves from '@/files/waves-lightblue.svg'
import SubscriptionManager from '@/components/User/BillingSubscription/components/SubscriptionManager'

const SubscriptionDetailsCard = () => {
  const billingDetails = useBillingDetails()
  const subscriptionLevel = billingDetails.data?.level as BillingSubscriptionLevel

  return (
    <EuiPanel
      paddingSize='l'
      css={css({
        backgroundImage: `url("${waves}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: 'contain',
        paddingRight: 70,
      })}
      hasShadow={false}
      hasBorder={true}
      data-test-id='subscription-details-card'
    >
      <EuiFlexGroup direction='column' justifyContent='spaceBetween' css={css({ height: '100%' })}>
        <EuiFlexItem grow={10}>
          <EuiText size='xs'>
            <strong>
              <FormattedMessage
                id='subscription-details-card.title'
                defaultMessage='SUBSCRIPTION'
              />
            </strong>
          </EuiText>

          <EuiSpacer size='m' />

          <EuiSkeletonLoading
            isLoading={billingDetails.loading}
            loadingContent={
              <div css={css({ maxWidth: 150 })}>
                <EuiSkeletonText lines={1} />
                <EuiSpacer size='xs' />
                <EuiSkeletonText lines={1} />
              </div>
            }
            loadedContent={
              <Fragment>
                <EuiTitle size='m' className='eui-textInheritColor'>
                  <h2 data-test-id='subscription-details-card.subscription-level'>
                    {capitalize(subscriptionLevel)}
                  </h2>
                </EuiTitle>
                <EuiSpacer size='xs' />
                <EuiText size='s'>
                  <FormattedMessage
                    id='subscription-details-card.support'
                    defaultMessage='with {supportLevel} support'
                    values={{ supportLevel: getSupportNameBasedOnLevel(subscriptionLevel!) }}
                  />
                </EuiText>
              </Fragment>
            }
          />
        </EuiFlexItem>

        <EuiSpacer size='m' />

        <EuiFlexItem>
          <div>
            <SubscriptionManager
              buttonText={
                <FormattedMessage
                  id='billing-subscription.change-plan-cta'
                  defaultMessage='Change plan'
                />
              }
              isButtonDisabled={billingDetails.loading}
            />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default SubscriptionDetailsCard
