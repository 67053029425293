/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'

import type { EuiButtonIconProps, IconType } from '@elastic/eui'
import { EuiButtonIcon, EuiPopover, EuiPopoverTitle } from '@elastic/eui'

import { useToggle } from '@modules/utils/hooks/useToggle'

import type { WrappedComponentProps } from 'react-intl'

interface Props extends WrappedComponentProps {
  content: React.ReactChild
  title?: React.ReactChild
  iconType?: IconType
  color?: EuiButtonIconProps['color']
}

const messages = defineMessages({
  helpIcon: {
    id: `cost-analysis.icon-tip-aria-label`,
    defaultMessage: `Learn more`,
  },
})

const IconTip = ({ content, title, iconType, color, intl: { formatMessage } }: Props) => {
  const [isPopoverOpen, togglePopover, setPopoverOpen] = useToggle()

  return (
    <EuiPopover
      anchorPosition='upRight'
      button={
        <EuiButtonIcon
          color={color || 'text'}
          iconType={iconType || 'iInCircle'}
          aria-label={formatMessage(messages.helpIcon)}
          onClick={togglePopover}
        />
      }
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen(false)}
    >
      {title && (
        <EuiPopoverTitle data-test-id='cost-analysis-icon-tip-title'>{title}</EuiPopoverTitle>
      )}
      <div data-test-id='cost-analysis-icon-tip-content'>{content}</div>
    </EuiPopover>
  )
}

export default injectIntl(IconTip)
