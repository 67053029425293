/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useReducer } from 'react'
import { useHistory } from 'react-router'

import { FiltersContext } from './filtersContext'
import { filtersReducer } from './filtersReducer'
import { parseFiltersQuery } from './utils'

import type { ReactNode } from 'react'

const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory()

  const [filters, dispatch] = useReducer(
    filtersReducer,
    parseFiltersQuery(history.location.search.slice(1)),
  )

  return <FiltersContext.Provider value={{ filters, dispatch }}>{children}</FiltersContext.Provider>
}

function withFiltersProvider<WrappedComponentProps>(WrappedComponent) {
  return (props: WrappedComponentProps) => (
    <FiltersProvider>
      <WrappedComponent {...props} />
    </FiltersProvider>
  )
}

export default withFiltersProvider
