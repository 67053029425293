/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import type { SaasResendVerificationRequest, SaasUserResponse } from '@modules/cloud-api/v1/types'
import { getSaasUserUrl, resendSaasVerificationEmailUrl } from '@modules/cloud-api/v1/urls'
import type { MultiErrorResponse } from '@modules/project-user-api/v1/types'
import { fetchAsJson } from '@modules/query/helpers'
import type { QueryHookOptions } from '@modules/query/types'

import type { UseMutationOptions } from 'react-query'

export const useGetSaasUserQuery = (userId: string | undefined, options?: QueryHookOptions) =>
  useQuery(
    ['saasUser', userId],
    () => {
      if (userId === undefined) {
        return Promise.reject() // sanity for `enabled` option
      }

      return fetchAsJson<SaasUserResponse>(getSaasUserUrl({ userId }), {
        method: 'get',
      })
    },
    options,
  )

export const useResendVerificationEmailMutation = (
  options?: Omit<
    UseMutationOptions<unknown, MultiErrorResponse, SaasResendVerificationRequest>,
    'mutationFn'
  >,
) =>
  useMutation<unknown, MultiErrorResponse, SaasResendVerificationRequest>(
    (payload: SaasResendVerificationRequest) =>
      fetchAsJson<unknown>(resendSaasVerificationEmailUrl(), {
        method: 'post',
        body: JSON.stringify(payload),
      }),
    options,
  )
