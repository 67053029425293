/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { isPrepaidConsumptionCustomer } from '@modules/billing-lib/utils'

import EcuHelpText from '@/components/EcuHelp/EcuHelpText'
import BillingUsageOverviewV2 from '@/components/User/BillingUsage/BillingUsageOverviewV2'
import BillingUsageByInstance from '@/components/User/BillingUsage/BillingUsageByInstance'

import CostOverview from './CostOverview'

import type { ReactElement } from 'react'
import type { AllProps } from './types'

class CostAnalysis extends PureComponent<AllProps> {
  render(): ReactElement {
    const { profile } = this.props

    return (
      <div>
        <EuiSpacer size='m' />

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h3>
                <FormattedMessage
                  id='cost-analysis.page-sub-title'
                  defaultMessage='Current month'
                />
              </h3>
            </EuiTitle>
          </EuiFlexItem>

          {isPrepaidConsumptionCustomer(profile) && (
            <EuiFlexItem grow={false}>
              <EuiText>
                <EcuHelpText />
              </EuiText>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <CostOverview profile={profile} />

        <EuiSpacer size='l' />

        {this.renderUsageDetails()}
      </div>
    )
  }

  renderUsageDetails(): ReactElement | null {
    const {
      instanceId,
      instanceName,
      profile: { organization_id },
      setInstanceName,
    } = this.props

    if (instanceId) {
      return (
        <BillingUsageByInstance
          organizationId={organization_id!}
          instanceId={instanceId}
          instanceName={instanceName}
        />
      )
    }

    return (
      <BillingUsageOverviewV2 organizationId={organization_id!} setInstanceName={setInstanceName} />
    )
  }
}

export default CostAnalysis
