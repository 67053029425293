/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { StackVersionConfigs } from '@modules/cloud-api/v1/types'
import { getVersionStacksUrl } from '@modules/cloud-api/v1/urls'
import type { Region } from '@modules/ui-types'

import { FETCH_VERSIONS } from '../../constants/actions'
import asyncRequest from '../asyncRequests'

export function fetchVersions(
  region: Region | string,
  {
    showUnusable = true,
    tossOldVersions = false,
  }: { showUnusable?: boolean; tossOldVersions?: boolean } = {},
) {
  /**
   * Note: Currently, the endpoint we are calling here ({region}/stack/versions) ignores
   * the value of the `showUnusable` parameter when used with an Elastic user (a user with an Elastic email).
   * It will always return unusable versions along with stable versions.
   * In contrast, for non-Elastic users, the endpoint behavior depends on the value of the `showUnusable` parameter:
   * it will either return only versions eligible for upgrade or all versions, including unusable ones.
   * Eligible versions include the most recent two minor releases of the current major release
   * and the final minor release of the previous major release in the presented options.
   */

  /* On the API side, `show_unusable` defaults to `false` to maintain backwards
   * compatibility. However, this is inconsistent with FETCH_VERSION, which
   * acts as if `show_unusable=true` were the default. For that reason, and because
   * we don't want to break BWC, we default to `show_unusable=true` on the
   * FETCH_VERSIONS action in the front-end, avoiding any potential issues that may
   * result from this inconsistency.
   *
   * Learn more:
   *   https://github.com/elastic/cloud/issues/35759
   *   https://github.com/elastic/cloud/pull/35769
   *   https://github.com/elastic/cloud/pull/35768
   *   https://github.com/elastic/cloud/pull/35774
   *   https://github.com/elastic/cloud/pull/36268
   */

  const regionId = typeof region === `string` ? region : region.id
  const url = getVersionStacksUrl({ regionId, showUnusable })

  return asyncRequest<typeof FETCH_VERSIONS, StackVersionConfigs>({
    type: FETCH_VERSIONS,
    url,
    meta: { regionId, tossOldVersions },
    crumbs: [regionId],
    abortIfInProgress: true,
  })
}
